import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../../../../components/breadcrumb';
import Table from '../../../../components/table';
import BaseAdministrativo from '../../baseAdministrativo';
import { useNavigate } from 'react-router-dom';
import urlsAdministrativo from '../../urlsAdministrativo';
import { useQuery, useMutation } from 'react-query'
import { listarImpressoras, deletarImpressora, listarImpressorasUnidades } from '../../../../services/administrativo/patrimonio';
import { toast } from 'react-toastify';
import { getUnidades } from '../../../../services/unidades';

const ImpressorasPage = () => {
  const navigate = useNavigate()
  const [newData, setNewData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [unidadeSelect, setUnidadeSelect] = useState('');

  const { data: unidades } = useQuery('unidades', getUnidades, {
    retry: true,
  })

  const { refetch: refetchData, isLoading } = useQuery('listarImpressoras', () => listarImpressoras(0,20), {
    enabled: true,
    retry: true,
    onSuccess: (data) => {
      setNewData(data);
    }
  })

  const { refetch: refetchImpressorasId } = useQuery('listarImpressorasUnidades', () => listarImpressorasUnidades(unidadeSelect, 0, 20), {
    enabled: false,
    retry: true,
    onSuccess: (data) => {
      setNewData(data);
    }
  })

  useEffect(() => {
    if (unidadeSelect !== '') {
      refetchImpressorasId()
    }
    if (unidadeSelect === '') {
      refetchData()
    }
  }, [unidadeSelect])

  const { mutate, isLoading: loadingDelete } = useMutation(id => deletarImpressora(id), {
    onSuccess: data => {
      toast.success('Impresssora excluída com sucesso.');
      refetchData();
    },
    onError: data => {
      toast.error('Erro ao excluir impressora. Tente novamente mais tarde.')
    }
  })

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Marca', accessor: 'marca' },
      { Header: 'Modelo', accessor: 'modelo' },
      { Header: 'Número Série', accessor: 'numeroSerie' },
      { Header: 'Unidade', accessor: 'unidadeNome' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
          {console.log(row)}
            <button onClick={() => navigate(urlsAdministrativo.patrimonioControleImpressorasCadastrar + row.original.id, {
              state: {
                
                id: row.original.id,
                marca: row.original.marca,
                modelo: row.original.modelo,
                numeroSerie: row.original.numeroSerie,
                unidadeId: row.original.unidadeId
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deletarImpressora(row.original.id)
                }
              }}
            >
              <i className="material-icons-two-tone">group_remove</i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, isLoading, newData, loadingDelete]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Impressoras'} itens={['Administrativo', 'Patrimônio', 'Impressoras']} actions={{ link: urlsAdministrativo.patrimonioControleImpressorasCadastrar, text: "Nova Impressora" }} />
      
      <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-12">
      <div className="form-group">
        <label htmlFor="unidadeId" className="form-label">Unidade</label>
        <select
          className="form-select"
          id="unidadeId"
          onChange={e => setUnidadeSelect(e.target.value)}
        >
              <option value=''>Selecione</option>

              {
                unidades?.map(unidade => (
                  <option value={unidade.id}>
                    {
                      unidade.nome
                    }
                  </option>
                ))
              }

        </select>
  </div>
</div>

</div>
      
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
            <Table 
            columns={columns}
            data={newData.data ? newData.data : []}
            hasPagination
            limit={limit}
            setLimit={setLimit}
            skip={skip}
            setSkip={setSkip}
            totalItems={newData.total}
            />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default ImpressorasPage;
