export const getEscolasAndPolos = (tipoFormulario, redeEnsino) => {
    let escolas = [];
    let polos = [];

    if ((tipoFormulario === 'BILINGUE_MILITAR' || tipoFormulario === 'BILINGUE_GASTRONOMIA_HOTELARIA') && redeEnsino === 'MUNICIPAL') {
        escolas = [
            'ESCOLA ESTADUAL MUNICIPALIZADA PARATI',
            'ESCOLA MUNICIPAL AGOSTINHO FRANCESCHI',
            'ESCOLA MUNICIPAL ANDERSON DOMINGUES DE OLIVEIRA',
            'ESCOLA MUNICIPAL ANDRÉ GOMES DOS SANTOS',
            'ESCOLA MUNICIPAL ANTONIO ANDRADE MÜLLER',
            'ESCOLA MUNICIPAL BRUNNO BARRETO NAMETALA',
            'ESCOLA MUNICIPAL CELINA MESQUITA PEDROSA',
            'ESCOLA MUNICIPAL DR. JOÃO VASCONCELLOS',
            'ESCOLA MUNICIPAL ECOLÓGICA PROFESSOR FÁBIO SIQUEIRA',
            'ESCOLA MUNICIPAL FAUSTINA SILVA CARVALHO',
            'ESCOLA MUNICIPAL FRANCISCO DOMINGUES NETO',
            'ESCOLA MUNICIPAL HONORINO COUTINHO',
            'ESCOLA MUNICIPAL JERÔNIMO CARLOS NASCIMENTO',
            'ESCOLA MUNICIPAL JOÃO AUGUSTO CHAVES',
            'ESCOLA MUNICIPAL JOÃO BRITO DE SOUZA',
            'ESCOLA MUNICIPAL JOAQUINA OLIVEIRA RANGEL',
            'ESCOLA MUNICIPAL JOSÉ CORRÊA DA FONSECA',
            'ESCOLA MUNICIPAL MARGARIDA TRINDADE DE DEUS',
            'ESCOLA MUNICIPAL MENINO ROBSON JUNIOR',
            'ESCOLA MUNICIPAL NEDIR PAULO BARROSO DA ROSA',
            'ESCOLA MUNICIPAL PASTOR ALCEBÍADES FERREIRA DE MENDONÇA',
            'ESCOLA MUNICIPAL PREFEITO ALTEVIR VIEIRA PINTO BARRETO',
            'ESCOLA MUNICIPAL PROF. CARLOS LEAL',
            'ESCOLA MUNICIPAL PROFª HEGLÁUCIA MARIA DE MELLO MATTA',
            // 'ESCOLA MUNICIPAL PROFESSOR DARCY RIBEIRO',
            'ESCOLA MUNICIPAL PROFESSOR PEDRO PAULO DE BRAGANÇA PIMENTEL',
            'ESCOLA MUNICIPAL PROFESSOR RAYMUNDO MAGNO CAMARÃO',
            'ESCOLA MUNICIPAL PROFESSORA NAIR VALLADARES',
            'ESCOLA MUNICIPAL SARA URRUTIA BAPTISTA',
            'ESCOLA MUNICIPAL SINVAL PINTO DE FIGUEIREDO',
            'ESCOLA MUNICIPAL TONINHO SENRA',
            'ESCOLA MUNICIPAL VER. ALCEBÍADES CARVALHO DAS FLORES',
            'ESCOLA MUNICIPAL VEREADOR EDEMUNDO PEREIRA DE SÁ CARVALHO',
            'ESCOLA MUNICIPAL VEREADOR MOYSÉS RAMALHO',
            'PRAÇA ESCOLA ECOLÓGICA MUNICIPAL DR. FERNANDO CARVALHO',
            'PRAÇA ESCOLA IGUABINHA - MARCOS HERON CORRÊA',
            'PRAÇA ESCOLA ITATIQUARA - PREF. AFRÂNIO VALADARES',
            'PRAÇA ESCOLA PRAIA SECA - COMANDANTE SÉRGIO RIBEIRO'
        ];
    } else if ((tipoFormulario === 'BILINGUE_MILITAR' || tipoFormulario === 'BILINGUE_GASTRONOMIA_HOTELARIA') && redeEnsino === 'PRIVADA') {
        escolas = [
            'CENTRO EDUC. CAMARGOS',
            'CENTRO EDUC. CERQUEIRA QUINTANILHA (SEMEANDO SABER)',
            'CENTRO EDUC. EXIMIUS (ZERO HUM)',
            'CENTRO EDUC. FUTURA',
            'CENTRO EDUC. MARGARIDA (CEM)',
            'CENTRO EDUC. MILTON MOTTA',
            'CENTRO EDUC. MONTEIRO CORREA',
            'CENTRO EDUC. NILDA MENDES (CENIM)',
            'CENTRO EDUC. OS SABIDINHOS DE ARARUAMA',
            'CENTRO EDUC. PRÉ UNIVERSITÁRIO',
            'CENTRO EDUC. RENASCER',
            'CENTRO EDUC. RODRIGUES MELLO (ABELHINHA FELIZ)',
            'CENTRO EDUC. TERRA NOVA DE ARARUAMA LTDA',
            'COLÉGIO ARARUAMA',
            'COLEGIO CURSOS CEJAN LTDA - ME',
            'COLÉGIO FUTURO',
            'COLEGIO LÓGICA',
            'COLÉGIO PROF. FERNANDO MOREIRA CALDAS',
            'COLEGIO SANTANNA',
            'OUTROS' // Allow manual entry for "OUTROS"
        ];
    } else if (tipoFormulario === 'BILINGUE_AGRONEGOCIO_TURISMO_RURAL') {
        escolas = [
            'ESCOLA MUNICIPAL AGOSTINHO FRANCESCHI',
            'ESCOLA MUNICIPAL FAUSTINA SILVA CARVALHO',
            'ESCOLA MUNICIPAL FRANCISCO DOMINGUES NETO',
            'ESCOLA MUNICIPAL HONORINO COUTINHO',
            'ESCOLA MUNICIPAL JERÔNIMO CARLOS NASCIMENTO',
            'ESCOLA MUNICIPAL JOÃO AUGUSTO CHAVES',
            'ESCOLA MUNICIPAL JOAQUINA OLIVEIRA RANGEL',
            'ESCOLA MUNICIPAL JOSÉ CORRÊA DA FONSECA',
            'ESCOLA MUNICIPAL NEDIR PAULO BARROSO DA ROSA',
            'ESCOLA MUNICIPAL PASTOR ALCEBÍADES FERREIRA DE MENDONÇA',
            'ESCOLA MUNICIPAL PROFª HEGLÁUCIA MARIA DE MELLO MATTA',
            'ESCOLA MUNICIPAL PROFESSOR PEDRO PAULO DE BRAGANÇA PIMENTEL',
            'ESCOLA MUNICIPAL VEREADOR EDEMUNDO PEREIRA DE SÁ CARVALHO',
            'PRAÇA ESCOLA ITATIQUARA - PREF. AFRÂNIO VALADARES'
        ];
    }

    // Polos for each `tipoFormulario`
    if (tipoFormulario === 'BILINGUE_MILITAR') {
        polos = [
            'Escola Bilíngue M. Sueli Amaral – Rua Bolívia, s/n°. Parque Hotel. (1°distrito)',
            'Escola Municipal Profª. Nair Valladares – Rua Prefeito Mário Alves, n°99. Parque Mataruna. (1°distrito)',
            'Praça Escola Pref. Afrânio Valladares – Estrada de Morro Grande, s/n°, LT 01/02 -QD F. Itatiquara (2°distrito)',
            'Colégio Municipal Prof. Pedro Paulo de B. Pimentel – Rua Terezinha, s/n°. Lot. Nova São Vicente. São Vicente. (3°distrito)',
            'Praça Escola Com. Sérgio Ribeiro de Vasconcellos – Rua Heron Domingues, s/n°. Praia Seca. (4°distrito)',
            'Escola Municipal André Gomes dos Santos – Rua Novo Horizonte, s/n°. Bananeiras. (5°distrito)'
        ];
    } else if (tipoFormulario === 'BILINGUE_GASTRONOMIA_HOTELARIA') {
        polos = [
            'Escola Bilíngue M. Prof. João Raposo – Rua Argentina, s/n°. Parque Hotel. (1°distrito)',
            'Escola Municipal Vereador Moysés Ramalho – Rua Vitória Helena, s/n°. Vila Capri. (1°distrito)',
            'Praça Escola Pref. Afrânio Valladares – Estrada de Morro Grande, s/n°, LT 01/02 -QD F. Itatiquara (2°distrito)',
            'Colégio Municipal Prof. Pedro Paulo de B. Pimentel – Rua Terezinha, s/n°. Lot. Nova São Vicente. São Vicente. (3°distrito)',
            'Praça Escola Com. Sérgio Ribeiro de Vasconcellos – Rua Heron Domingues, s/n°. Praia Seca. (4°distrito)',
            'Escola Municipal André Gomes dos Santos – Rua Novo Horizonte, s/n°. Bananeiras. (5°distrito)'
        ];
    } else if (tipoFormulario === 'BILINGUE_AGRONEGOCIO_TURISMO_RURAL') {
        polos = [
            'Escola Bilíngue M. Oscarino Pereira de Andrade – Rua Prefeito Antônio Raposo, s/n°. São Vicente de Paulo. (3°distrito)',
            'Colégio Municipal Honorino Coutinho – Rod. RJ 136. Estrada de Morro Grande. Morro Grande. (2°distrito)'
        ];
    }

    return {
        escolas: escolas.map(escola => escola.toUpperCase()), // Convert to uppercase
        polos: polos.map(polo => polo.toUpperCase()) // Convert to uppercase
    };
};
