import React from 'react';
import { Route } from 'react-router-dom';
import urlsAdministrativo from '../../pages/administrativo/urlsAdministrativo';
import HomeAdministrativo from '../../pages/administrativo/homeAdministrativo';
import ControleFornecedores from '../../pages/administrativo/patrimonios/controleFornecedores';
import ControleFornecedoresForm from '../../pages/administrativo/patrimonios/controleFornecedores/cadastrar';
import ControleContratos from '../../pages/administrativo/patrimonios/controleContratos';
import ContratosForm from '../../pages/administrativo/patrimonios/controleContratos/cadastrar';
import CategoriaProdutosTable from '../../pages/administrativo/patrimonios/categoriaProdutos';
import CategoriaProdutosForm from '../../pages/administrativo/patrimonios/categoriaProdutos/cadastrar';
import ControleProdutosTable from '../../pages/administrativo/patrimonios/controleProdutos';
import ProdutosForm from '../../pages/administrativo/patrimonios/controleProdutos/cadastrar';
import ControleEstoque from '../../pages/administrativo/patrimonios/controleEstoque';
import ControleEstoqueForm from '../../pages/administrativo/patrimonios/controleEstoque/cadastrar';
import DashboardTransporte from '../../pages/administrativo/transporte/dashboard';
import TransporteAlunos from '../../pages/administrativo/transporte/alunos';
import TransporteFormularioPage from '../../pages/administrativo/transporte/formulario';
import AlunosPage from '../../pages/alunos';
import ContasTable from '../../pages/administrativo/financeiro/contas';
import ContasForm from '../../pages/administrativo/financeiro/contas/cadastrar';
import ProcessosTable from '../../pages/administrativo/financeiro/processos';
import MemorandosTable from '../../pages/administrativo/financeiro/memorandos';
import NutricaoFornecedores from '../../pages/administrativo/nutricao/fornecedor';
import TransporteVeiculos from '../../pages/administrativo/transporte/veiculos';
import VeiculoForm from '../../pages/administrativo/transporte/veiculos/cadastrar';
import LancarGastosForm from '../../pages/administrativo/transporte/veiculos/lancar-gastos';
import ImpressorasPage from '../../pages/administrativo/patrimonios/controleImpressoras';
import ImpressoraCadastrarForm from '../../pages/administrativo/patrimonios/controleImpressoras/cadastrar';
import NutricaoCardapios from '../../pages/administrativo/nutricao/cardapio';
import NutricaCardapioForm from '../../pages/administrativo/nutricao/cardapio/cadastrar';
import ImpressaoCardapio from '../../pages/administrativo/nutricao/cardapio/impressao';
import NutricaoControleProduto from '../../pages/administrativo/nutricao/produto';
import NutricaoProdutosForm from '../../pages/administrativo/nutricao/produto/cadastrar';
import NutricaoCategoriaProdutos from '../../pages/administrativo/nutricao/categoriaProduto';
import NutricaoCategoriaProdutosForm from '../../pages/administrativo/nutricao/categoriaProduto/cadastrar';


const defaultPermisson = false;

const AdministrativoRoutes = () => (
    <React.Fragment>
        <Route exact path={urlsAdministrativo.dashboard} element={ <HomeAdministrativo />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleFornecedores} element={ <ControleFornecedores />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleFornecedoresCadastrar} element={ <ControleFornecedoresForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleFornecedoresCadastrarId} element={ <ControleFornecedoresForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleContratos} element={ <ControleContratos />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleContratosCadastrar} element={ <ContratosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleContratosCadastrarId} element={ <ContratosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioCategoriaProdutos} element={ <CategoriaProdutosTable />}/>
        <Route exact path={urlsAdministrativo.patrimonioCategoriaProdutosCadastrar} element={ <CategoriaProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioCategoriaProdutosCadastrarId} element={ <CategoriaProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleProdutos} element={ <ControleProdutosTable />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleProdutosCadastrar} element={ <ProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleProdutosCadastrarId} element={ <ProdutosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleEstoque} element={ <ControleEstoque />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleEstoqueCadastrar} element={ <ControleEstoqueForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleEstoqueCadastrarId} element={ <ControleEstoqueForm />}/>
        <Route exact path={urlsAdministrativo.transporteDashboard} element={ <DashboardTransporte />}/>
        <Route exact path={urlsAdministrativo.transporteAlunos} element={ <AlunosPage gestao={false} administrativo={true} />}/>
        <Route exact path={urlsAdministrativo.transporteFormulario} element={ <TransporteFormularioPage />}/>
        <Route exact path={urlsAdministrativo.financeiroContasAPagar} element = { <ContasTable />}/>
        <Route exact path={urlsAdministrativo.financeiroContasAPagarCadastrar} element = { <ContasForm />}/>
        <Route exact path={urlsAdministrativo.financeiroContasAPagarCadastrarId} element = { <ContasForm />}/>
        <Route exact path={urlsAdministrativo.financeiroProcessos} element={ <ProcessosTable />}/>
        <Route exact path={urlsAdministrativo.financeiroMemorandos} element={ <MemorandosTable />}/>
        <Route exact path={urlsAdministrativo.nutricaoFornecedor} element={ <NutricaoFornecedores />}/>
        <Route exact path={urlsAdministrativo.transporteVeiculos} element={ <TransporteVeiculos />}/>
        <Route exact path={urlsAdministrativo.transporteVeiculosCadastrar} element={ <VeiculoForm />}/>
        <Route exact path={urlsAdministrativo.transporteVeiculosCadastrarId} element={ <VeiculoForm />}/>
        <Route exact path={urlsAdministrativo.transporteVeiculosLancarGastosId} element={ <LancarGastosForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleImpressoras} element={ <ImpressorasPage />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleImpressorasCadastrar} element={ <ImpressoraCadastrarForm />}/>
        <Route exact path={urlsAdministrativo.patrimonioControleImpressorasCadastrarId} element={ <ImpressoraCadastrarForm />}/>
        <Route exact path={urlsAdministrativo.nutricaoCardapio} element={ <NutricaoCardapios />}/>
        <Route exact path={urlsAdministrativo.nutricaoCardapioCadastrar} element={ <NutricaCardapioForm />}/>
        <Route exact path={urlsAdministrativo.nutricaoCardapioImpressao} element={ <ImpressaoCardapio />}/>
        <Route exact path={urlsAdministrativo.nutricaoProduto} element={ <NutricaoControleProduto />}/>
        <Route exact path={urlsAdministrativo.nutricaoProdutoCadastrar} element={ <NutricaoProdutosForm />}/>
        <Route exact path={urlsAdministrativo.nutricaoProdutoCadastrarId} element={ <NutricaoProdutosForm />}/>
        <Route exact path={urlsAdministrativo.nutricaoCategoriaProduto} element={ <NutricaoCategoriaProdutos />}/>
        <Route exact path={urlsAdministrativo.nutricaoCategoriaProdutoCadastrar} element={ <NutricaoCategoriaProdutosForm />}/>
        <Route exact path={urlsAdministrativo.nutricaoCategoriaProdutoCadastrarId} element={ <NutricaoCategoriaProdutosForm />}/>
    </React.Fragment>
);

export default AdministrativoRoutes;
