import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsBilingue from '../pages/formularios/bilingue/UrlsBilingue';
import BilingueHomePage from '../pages/formularios/bilingue/pages/BilingueHomePage';
import BilingueFormularioPage from '../pages/formularios/bilingue/pages/BilingueFormularioPage';
import BilingueVisualizacaoPage from '../pages/formularios/bilingue/pages/BilingueVisualizacaoPage';


const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const FormulariosRoute = () => (
    <React.Fragment>
    {enable && (
    <>
      
      {/* <Route path={urlsBilingue.formulario} element={<BilingueHomePage />} />
        <Route path={urlsBilingue.formularioMilitar} element={<BilingueFormularioPage tipoFormulario={"BILINGUE_MILITAR"} />} />
        <Route path={urlsBilingue.formularioGastronomiaHotelaria} element={<BilingueFormularioPage tipoFormulario={"BILINGUE_GASTRONOMIA_HOTELARIA"} />} />
        <Route path={urlsBilingue.formularioAgronegocioTurismo} element={<BilingueFormularioPage tipoFormulario={"BILINGUE_AGRONEGOCIO_TURISMO_RURAL"} />} />
        <Route path={urlsBilingue.formularioVisualizacaoId} element={<BilingueVisualizacaoPage />} /> */}

    </>
    )}
  </React.Fragment>
);

export default FormulariosRoute;
