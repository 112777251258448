import urlsGestao from "./urlsGestao";

const links = (
  moduloGestaoGrupoGestaoEducacional,
  moduloGestaoGrupoAcademico,
  moduloGestaoGrupoDiario,
  moduloGestaoFrequenciaServidores
) => {
  return {
    gestao_educacional: {
      id: "gestao_educacional",
      text: "GESTÃO EDUCACIONAL",
      type: "title",
      visible: moduloGestaoGrupoGestaoEducacional == 1,
      itens: {
        dashboard: {
          id: "dashboard",
          text: "DASHBOARD",
          path: urlsGestao.dashboard,
          icon: "dashboard",
          visible: moduloGestaoGrupoGestaoEducacional == 1,
        },

        notificacoes: {
          id: "notificacoes",
          text: "NOTIFICAÇÕES",
          path: urlsGestao.gestaoAcademicoNotificacoes,
          icon: "notifications_active",
          visible: false,
        },
        servidores: {
          id: "colaboradores",
          text: "SERVIDORES",
          path: urlsGestao.servidores,
          icon: "badge",
          visible: moduloGestaoGrupoGestaoEducacional == 1,
        },
        servidores2: {
          id: "colaboradores2",
          text: "FREQUÊNCIA",
          path: urlsGestao.servidorFrenquenciaLancamento,
          icon: "schedule",
          visible: moduloGestaoFrequenciaServidores === 1,
        },
        calendario: {
          id: "calendario",
          text: "CALENDÁRIO",
          path: urlsGestao.calendario,
          icon: "badge",
          visible: false,
        },
      },
    },

    academico: {
      id: "academico",
      text: "ACADÊMICO",
      type: "title",
      visible: moduloGestaoGrupoAcademico == 1,
      itens: {
        alunos: {
          id: "alunos",
          text: "ALUNOS",
          path: urlsGestao.alunos,
          icon: "groups",
          visible: moduloGestaoGrupoAcademico == 1,
        },
        turmas: {
          id: "turmas",
          text: "TURMAS",
          path: urlsGestao.turmas,
          icon: "class",
          visible: moduloGestaoGrupoAcademico == 1,
        },
      },
    },

    diario: {
      id: "academico",
      text: "DIÁRIO",
      type: "title",
      visible: moduloGestaoGrupoDiario == 1,
      itens: {
        qaudro: {
          id: "quadro",
          text: "QUADRO DE HORÁRIO",
          path: urlsGestao.quadroHorario,
          icon: "calendar_view_week",
          visible: moduloGestaoGrupoDiario == 1,
        },
        // diario: {
        //   id: 'diario',
        //   text: 'DIÁRIO DE CLASSE',
        //   path: urlsGestao.diarioClasse,
        //   icon: 'collections_bookmark',
        //   visible: true,
        // },
        // faltas: {
        //   id: 'faltas',
        //   text: 'FALTAS',
        //   path: urlsGestao.faltas,
        //   icon: 'border_color',
        //   visible: moduloGestaoGrupoAcademico == 1,
        // },
        bnncFundamental: {
          id: "bnnc-fundamentos",
          text: "BNCC FUNDAMENTAL",
          path: urlsGestao.bnccFundamental,
          icon: "collections_bookmark",
          visible: true,
        },
      },
    },
    biblioteca: {
      id: "biblioteca",
      text: "BIBLIOTECA",
      type: "title",
      visible: moduloGestaoGrupoGestaoEducacional == 1,
      itens: {
        biblioteca: {
          id: "biblioteca",
          text: "BIBLIOTECA",
          path: urlsGestao.biblioteca,
          icon: "collections_bookmark",
          visible: moduloGestaoGrupoGestaoEducacional == 1,
        },
      },
    },
  };
};

export default links;
