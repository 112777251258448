import api from "..";
import { mock } from "./mock";

async function listarFichaIndividualInfantil(alunoId) {
  return await api
    .get(`fichaIndividual/infantil?alunoId=${alunoId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
}

async function listarFichaIndividualFundamental() {
  const ficha = mock;

  if (ficha) {
    return ficha;
  } else {
    throw new Error("Ficha não encontrada");
  }
}

export { listarFichaIndividualInfantil, listarFichaIndividualFundamental };
