import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RelatorioAlunosTransporte from '../../pages/relatorios/alunos/alunosTransporte';
import ReportCard from '../../pages/desenvolvimento';
import FichaIndividualInfantil from '../../pages/ficha-individual/infantil';
import EduAmigoFale from '../../pages/eduAmigo/fale';
import EduAmigoExplica from '../../pages/eduAmigo/explica';
import EduAmigoHome from '../../pages/eduAmigo/home';
import FichaIndividualFundamental from '../../pages/ficha-individual/fundamental';

const defaultPath = '/desenvolvimento';

const DesenvolvimentoRoutes = () => (
    <React.Fragment>
        <Route exact path={`${defaultPath}/1`} element={ <ReportCard />}/>
        <Route exact path={`${defaultPath}/2`} element={ <FichaIndividualInfantil />}/>
        <Route exact path={`${defaultPath}/eduAmigo/fale`} element={ <EduAmigoFale />}/>
        <Route exact path={`${defaultPath}/eduAmigo/explica`} element={ <EduAmigoExplica />}/>
        <Route exact path={`${defaultPath}/eduAmigo`} element={ <EduAmigoHome />}/>
        <Route exact path={`${defaultPath}/3`} element={ <FichaIndividualFundamental />}/>
       
        
    </React.Fragment>
);

export default DesenvolvimentoRoutes;
