import React, { forwardRef, useEffect, useState } from "react";
import { useTable } from "react-table";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from '@mui/system';

const PaginationSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '1rem',
  backgroundColor: theme.palette.background.paper,
  padding: '0.5rem 1rem',
  borderRadius: '8px',
  boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
}));

const SelectAmount = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const SelectPage = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const NumberButton = styled(Button)(({ theme }) => ({
  minWidth: '40px',
  marginLeft: '10px',
  marginRight: '10px',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ModernTable = styled(MuiTable)(({ theme }) => ({
  '& thead th': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    borderBottom: 'none',
  },
  '& tbody td': {
    borderBottom: 'none',
    padding: '10px',
  },
  '& tbody tr:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableComponent = forwardRef(
  (
    {
      columns,
      data,
      hasPagination,
      limit = 20,
      setLimit,
      skip = 0,
      setSkip,
      totalItems,
      isBoardTable = false,
      hiddenColluns = [],
      overflowUnset = false,
    },
    ref
  ) => {
    const [itemsPerPage, setItemsPerPage] = useState(limit);
    const [currentPage, setCurrentPage] = useState(Math.ceil(skip / limit) + 1); // Derive the page from skip
    const [buttonsAmount, setButtonsAmount] = useState([]);
    const [newData, setNewData] = useState(data);

    useEffect(() => {
      // Adjust current page when skip or limit changes
      setCurrentPage(Math.ceil(skip / limit) + 1);
      setNewData(data);
    }, [data, skip, limit]);

    useEffect(() => {
      const pageCount = Math.ceil(totalItems / itemsPerPage);
      const buttons = [];

      for (let i = 1; i <= pageCount; i++) {
        buttons.push(i);
      }
      if (buttons.length === 0) {
        buttons.push(1);
        setCurrentPage(1);
      }
      setButtonsAmount(buttons);
    }, [itemsPerPage, totalItems]);

    const handlePageNumber = (page) => {
      setCurrentPage(page);
      setSkip((page - 1) * itemsPerPage);
    };

    const handleAmountView = (amount) => {
      setItemsPerPage(amount);
      setLimit(amount);
      setSkip(0); // Reset skip when changing rows per page
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
      columns,
      data: newData,
      initialState: {
        hiddenColumns: hiddenColluns,
      },
    });

    return (
      <TableContainer
        component={Paper}
        style={{
          overflow: overflowUnset ? "unset" : "auto",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <ModernTable ref={ref} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>{column.render("Header")}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
                  ))}
                </TableRow>
              );
            })}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {isBoardTable
                    ? "Utilize os filtros para visualizar os quadros disponíveis"
                    : "Nenhum registro encontrado"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </ModernTable>
        {hasPagination && totalItems > 0 && (
          <PaginationSection>
            <SelectAmount>
              <Typography variant="body2" component="span" marginRight="0.5rem">
                Itens por página:
              </Typography>
              <Select
                onChange={(e) => handleAmountView(parseInt(e.target.value))}
                value={itemsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              <Typography variant="body2" component="span" marginLeft="0.5rem">
                de {totalItems}
              </Typography>
            </SelectAmount>
            <SelectPage>
              <Typography variant="body2" component="span" marginRight="0.5rem">
                Página:
              </Typography>
              <IconButton
                onClick={() => handlePageNumber(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Select
                onChange={(e) => handlePageNumber(parseInt(e.target.value))}
                value={currentPage}
              >
                {buttonsAmount.map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              <IconButton
                onClick={() => handlePageNumber(currentPage + 1)}
                disabled={currentPage === buttonsAmount.length}
              >
                <NavigateNextIcon />
              </IconButton>
            </SelectPage>
          </PaginationSection>
        )}
      </TableContainer>
    );
  }
);

TableComponent.displayName = "TableComponent";

export default TableComponent;
