import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../../../components/breadcrumb";
import BaseAdministrativo from "../../../baseAdministrativo";
import urlsAdministrativo from "../../../urlsAdministrativo";
import { useMutation } from 'react-query';
import upperCaseSimple from "../../../../../utils/upperCaseSimple";
import removeEmpty from "../../../../../utils/removeEmpty";
import { getEscolaridades } from "../../../../../services/escolaridades";
import { getUnidades } from "../../../../../services/administrativo/transporte";
import { getNutricaoProduto, addCardapio } from "../../../../../services/nutricao";
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Table from "../../../../../components/table";

const NutricaCardapioForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [alimentos, setAlimentos] = useState([]);
    const [produto, setProduto] = useState(0);
    const [refeicao, setRefeicao] = useState(0);
    const [diaSemana, setDiaSemana] = useState(0);
    const { state } = useLocation();
    const [selectedRow, setSelectedRow] = useState({})

    const { mutate, isLoading } = useMutation(id ? addCardapio : addCardapio, {
        onSuccess: (message) => {
            toast.success(message);
            navigate(urlsAdministrativo.nutricaoCardapio);
        },
        onError: (error) => {
            if (error.response) {
                const { message } = error.response.data;
                toast.error(message);
            } else if (error.request) {
                toast.error('Erro ao tentar cadastrar cardapio, tente novamente mais tarde.');
            } else {
                toast.error(error.message);
            }
        }
    });

    const { data: escolaridades } = useQuery('getEscolaridades', () => getEscolaridades(), {
        enabled: true,
        retry: true
    })

    const { data: unidades } = useQuery('getUnidades', () => getUnidades(), {
        enabled: true,
        retry: true
    })

    const { data: produtos } = useQuery('getNutricaoProduto', () => getNutricaoProduto(0, 9999), {
        enabled: true,
        retry: true
    })

    const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
        mode: "onChange", defaultValues: {
            idEscolaridade: '',
            idUnidade: '',
            dataInicio: '',
            dataFim: ''
        }
    });

    const onSubmit = (data) => {
        var dt = {
            cardapioDTO:{
                idEscolaridade: parseInt(data.idEscolaridade),
                idUnidade: parseInt(data.idUnidade),
                dataInicio: data.dataInicio,
                dataFim: data.dataFim
            },
            alimentos: alimentos
        }
        if (id) {
            mutate({ id, data: upperCaseSimple(removeEmpty(dt)) });
        } else {
            mutate(upperCaseSimple(removeEmpty(dt)));
        }
    };

    useMemo(() => {
        (async () => {
            if (state) {
                const { nacionalidade } = state;
                if (id && nacionalidade) {
                    const { descricao } = nacionalidade;
                    setValue('descricao', descricao);
                }
            }
        })();
    }, [id, state, setValue]);


    const adicionarAlimento = () => {
        if (produto == 0 || refeicao == 0 || diaSemana == 0) {
            toast.error("Selecione o produto, refeição e dia da semana para adicionar!");
            return;
        }
        var alimento = {
            id: alimentos.length == 0 ? 1 : alimentos[alimentos.length - 1].id + 1,
            idProduto: parseInt(produto),
            nomeProduto: produtos.find(x => x.id == parseInt(produto)).nome,
            idRefeicao: parseInt(refeicao),
            nomeRefeicao: tipoRefeicao(parseInt(refeicao)),
            idDiaSemana: parseInt(diaSemana),
            nomeDiaSemana: retornDiaSemana(parseInt(diaSemana))
        };
        setAlimentos([...alimentos, alimento]);
    }

    const tipoRefeicao = (idRefeicao) => {
        switch (idRefeicao) {
            case 1:
                return "DESJEJUM/ LANCHE DA TARDE";
                break;
            case 2:
                return "ALMOÇO";
                break;
            case 3:
                return "SOBREMESA";
                break;

            default:
                break;
        }
    }

    const retornDiaSemana = (idDiaSemana) => {
        switch (idDiaSemana) {
            case 1:
                return "DOMINGO";
                break;
            case 2:
                return "SEGUNDA-FEIRA";
                break;
            case 3:
                return "TERÇA-FEIRA";
                break;
            case 4:
                return "QUARTA-FEIRA";
                break;
            case 5:
                return "QUINTA-FEIRA";
                break;
            case 6:
                return "SEXTA-FEIRA";
                break;
            case 7:
                return "SABADO";
                break;

            default:
                break;
        }
    }

    const deletarAlimento = (id) => {
        alimentos.forEach((item, index) => {
            if (item.id === id) alimentos.splice(index, 1);
        });
        setAlimentos([...alimentos]);
    }

    const columns = useMemo(
        () => [
            { Header: '#', accessor: 'id' },
            { Header: 'Alimento', accessor: 'nomeProduto' },
            { Header: 'Refeição', accessor: 'nomeRefeicao' },
            { Header: 'Dia ', accessor: 'nomeDiaSemana' },
            {
                Header: '', accessor: 'actions',
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {` `}
                        <button
                            className='btn btn-sm btn-danger'
                            onClick={() => {
                                const confirm = window.confirm('Tem certeza que deseja excluir esse alimento?');
                                if (confirm) {
                                    deletarAlimento(row.values.id);
                                }
                            }}
                        >
                            <i className="material-icons-two-tone">group_remove</i> excluir
                        </button>
                    </div>
                )
            },
        ],
        [navigate, isLoading, alimentos]
    );

    return (
        <BaseAdministrativo>
            <Breadcrumb title={'Cardápio'} itens={['Administrativo', 'Nutrição', 'Cardápio']} />
            <form id='form' onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <label htmlFor="idEscolaridade" className="form-label">Escolaridade</label>
                                            <select className="form-select" id="idEscolaridade" {...register("idEscolaridade", { required: true })}>
                                                <option value={''}>SELECIONE</option>
                                                {escolaridades && escolaridades.map(item => (
                                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                                ))}
                                            </select>
                                            {errors.idEscolaridade?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="idUnidade" className="form-label">Escolaridade</label>
                                            <select className="form-select" id="idUnidade" {...register("idUnidade", { required: true })}>
                                                <option value={''}>SELECIONE</option>
                                                {unidades && unidades?.data?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                                ))}
                                            </select>
                                            {errors.idUnidade?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                        </div>
                                        <div className='col-2'>
                                            <label htmlFor="dataInicio" className="form-label">Data Inicio</label>
                                            <input type="date" className="form-control" id="dataInicio"
                                                {...register("dataInicio", { required: true })} />
                                            {errors.dataInicio?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                        </div>
                                        <div className='col-2'>
                                            <label htmlFor="dataFim" className="form-label">Data Fim</label>
                                            <input type="date" className="form-control" id="dataFim"
                                                {...register("dataFim", { required: true })} />
                                            {errors.dataFim?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                        </div>
                                    </div>

                                    <hr></hr>

                                    <div className='row' style={{ marginBottom: 20 }}>
                                        <div className='col-5'>
                                            <label htmlFor="idProduto1" className="form-label">Alimento</label>
                                            <select className="form-select" id="idProduto1" value={produto} onChange={(event) => setProduto(event.target.value)}>
                                                <option value={0}>SELECIONE</option>
                                                {produtos && produtos.map(item => (
                                                    <option key={item.id} value={item.id}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-3'>
                                            <label htmlFor="idRefeicao1" className="form-label">Refeição</label>
                                            <select className="form-select" id="idRefeicao1" value={refeicao} onChange={(event) => setRefeicao(event.target.value)}>
                                                <option value={0}>SELECIONE</option>
                                                <option value={1}>DESJEJUM/LANCHE DA TARDE</option>
                                                <option value={2}>ALMOÇO</option>
                                                <option value={3}>SOBREMESA</option>
                                            </select>
                                        </div>
                                        <div className='col-3'>
                                            <label htmlFor="idDiaSemana1" className="form-label">Dia</label>
                                            <select className="form-select" id="idDiaSemana1" value={diaSemana} onChange={(event) => setDiaSemana(event.target.value)}>
                                                <option value={0}>SELECIONE</option>
                                                <option value={1}>DOMINGO</option>
                                                <option value={2}>SEGUNDA-FEIRA</option>
                                                <option value={3}>TERÇA-FEIRA</option>
                                                <option value={4}>QUARTA-FEIRA</option>
                                                <option value={5}>QUINTA-FEIRA</option>
                                                <option value={6}>SEXTA-FEIRA</option>
                                                <option value={7}>SABADO</option>
                                            </select>
                                        </div>
                                        <div className="col-1">
                                            <button type="button" onClick={adicionarAlimento} style={{ marginTop: 35 }} className='btn btn-sm btn-success'><i className="material-icons-two-tone"></i>Adicionar</button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <Table columns={columns} data={alimentos} selected={selectedRow} />
                                    </div>

                                </>
                            </div>
                            <div className='card-footer text-end'>
                                <button type='submit' className='btn btn-success' disabled={!isValid || isLoading}>
                                    {isLoading ? 'enviando...' : id ? 'Atualizar' : 'Cadastrar'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BaseAdministrativo>
    )

};

export default NutricaCardapioForm;