import React, { useEffect, useRef, useState } from "react";
import { listarFichaIndividualInfantil } from "../../../services/ficha-individual";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import "./style.css";

const FichaIndividualInfantil = (gestao) => {
  const [ficha, setFicha] = useState(null); // Estado para armazenar a ficha individual
  const [loading, setLoading] = useState(true); // Estado para controlar o loading
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";
  const printRef = useRef(null);

  const resultados = [
    { label: "Transferido", value: 1 },
    { label: "Aprovado", value: 2 },
    { label: "Aprovado pelo Conselho de Classe", value: 3 },
    { label: "Reprovado", value: 4 },
    { label: "Evadido", value: 5 },
    { label: "Matrícula Indeferida", value: 6 },
    { label: "Temporalidade flexível", value: 7 },
    { label: "Promovido", value: 8 },
    { label: "Retido", value: 9 },
  ];

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      resultado: "",
      observacoes: "",
      diasLetivos: null,
    },
  });

  const resultado = watch("resultado");
  const observacoes = watch("observacoes");
  const diasLetivos = watch("diasLetivos");

  const onSubmit = () => {
    // Adicione aqui o código para salvar as alterações na ficha individual
    console.log("Salvar alterações na ficha individual");
    console.log(getValues()); // Mostra os valores do formulário
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "FICHA_INDIVIDUAL_ALUNO",
  });

  useEffect(() => {
    // Função que busca os dados da API
    const fetchFicha = async () => {
      try {
        const response = await listarFichaIndividualInfantil(1);
        setFicha(response); // Armazena os dados no estado
        setLoading(false); // Desabilita o loading
      } catch (error) {
        console.error("Erro ao buscar a ficha individual:", error);
        setLoading(false); // Desabilita o loading, mesmo em caso de erro
      }
    };

    fetchFicha(); // Chama a função assim que o componente é montado
  }, []);

  if (loading) {
    return <p>Carregando...</p>; // Exibe mensagem de carregamento
  }

  if (!ficha) {
    return <p>Erro ao carregar a ficha.</p>; // Exibe mensagem de erro, caso não tenha ficha
  }

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Ficha Individual"}
        itens={[BasePath, "Alunos", "Documentos"]}
        onClickImprimir={handlePrint}
        imprimir={{ link: "fda", text: "Imprimir" }}
      />
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center">
              <h5>Resultado da Avaliação</h5>
              <div className="d-flex gap-2">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Resultado
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="resultado"
                      {...register("resultado", {
                        required: true,
                      })}
                    >
                      {resultados.map((resultado) => (
                        <option key={resultado.value} value={resultado.value}>
                          {resultado.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Observações
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      id="observacoes"
                      {...register("observacoes", {
                        required: true,
                        maxLength: 200,
                      })}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Dias Letivos
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="diasLetivos"
                      {...register("diasLetivos", {
                        required: true,
                        min: 0,
                        max: 30,
                      })}
                    ></input>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                onClick={onSubmit}
                className="btn btn-success mt-2"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="ficha-container" ref={printRef}>
        <div className="d-flex justify-content-between pt-5">
          <div style={{ width: "250px" }}>
            <img
              src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
              width="100"
              height="100"
              alt=""
            ></img>
          </div>
          <div className="text-center">
            <h5>ESTADO DO RIO DE JANEIRO</h5>
            <h5>{process.env.REACT_APP_PREFEITURA}</h5>
            <h5>SECRETARIA MUNICIPAL DE EDUCAÇÃO</h5>
            <h5>UNIDADE AQUI</h5>
          </div>
          <div>
            <img
              src={`/assets/images/prefeituras/logo-pmspa-azul.png`}
              width="250"
              height="80"
              alt=""
            ></img>
          </div>
        </div>
        <h2 className="mt-3">FICHA INDIVIDUAL DA EDUCAÇÃO INFANTIL</h2>
        <table className="ficha-table">
          <tbody>
            <tr>
              <td>ALUNO: {ficha.aluno.nome}</td>
              <td>SEXO: {ficha.aluno.sexo}</td>
            </tr>
          </tbody>
        </table>
        <table className="ficha-table">
          <tbody>
            <tr>
              <td>DATA DE NASCIMENTO: {ficha.aluno.dataNascimento}</td>
              <td>NATURALIDADE: {ficha.aluno.naturalidade}</td>
              <td>UF: {ficha.aluno.uf}</td>
            </tr>
          </tbody>
        </table>
        <table className="ficha-table mb-2">
          <tbody>
            <tr>
              <td>FILIAÇÃO 1: {ficha.aluno.pai}</td>
              <td>FILIAÇÃO 2: {ficha.aluno.mae}</td>
            </tr>
          </tbody>
        </table>

        <table className="experiencia-table">
          <thead>
            <tr>
              <th>TURMA: {ficha.aluno.turma}</th>
              <th>TURNO: {ficha.aluno.turno}</th>
              <th>NÚMERO: {ficha.aluno.numero}</th>
              <th>ANO LETIVO: {ficha.aluno.anoLetivo}</th>
            </tr>
          </thead>
        </table>
        <table className="experiencia-table mt-2">
          <thead>
            <tr>
              <th rowSpan="2">Campos de Experiência</th>
              <th colSpan="4">FALTAS</th>
              <th colSpan="4">HORAS-AULAS/AULAS</th>
            </tr>
            <tr>
              <th>1º TRI</th>
              <th>2º TRI</th>
              <th>3º TRI</th>
              <th>T.F.</th>
              <th>1º TRI</th>
              <th>2º TRI</th>
              <th>3º TRI</th>
              <th>TOTAL HORAS</th>
            </tr>
          </thead>
          <tbody>
            {ficha.camposDeExperiencias.map((campo, index) => (
              <tr key={index}>
                <td>{campo.campo}</td>
                <td>{campo.faltas.primeiroTrimestre}</td>
                <td>{campo.faltas.segundoTrimestre}</td>
                <td>{campo.faltas.terceiroTrimestre}</td>
                <td>{campo.faltas.totalFaltas}</td>
                <td>{campo.horasAulas.primeiroTrimestre}</td>
                <td>{campo.horasAulas.segundoTrimestre}</td>
                <td>{campo.horasAulas.terceiroTrimestre}</td>
                <td>{campo.horasAulas.totalHoras}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="ficha-table mt-2">
          <thead>
            <tr>
              <td>TOTAL DE FALTAS: {ficha.totalGeral.faltas.totalFaltas}</td>
              <td>TOTAL DE HORAS: {ficha.totalGeral.horasAulas.totalHoras}</td>
              <td>
                PERCENTUAL DE FREQUÊNCIA:{" "}
                {ficha.totalGeral.percentualFrequencia}%
              </td>
            </tr>
          </thead>
        </table>

        <table className="resultado-final-table mt-2">
          <tbody>
            <tr>
              <td style={{ maxWidth: "120px" }}>
                RESULTADO:{" "}
                {resultados.find((r) => r.value === Number(resultado))?.label ||
                  "N/A"}
              </td>
              <td>DATA: {ficha.resultadoFinal.data}</td>
              <td style={{ maxWidth: "60px" }}>DIAS LETIVOS: {diasLetivos}</td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex mt-2" style={{ height: 80 }}>
          <div className="border-black w-25 d-flex justify-content-center align-items-center">
            OBSERVAÇÕES
          </div>
          <div className="d-flex align-items-center ps-2 border-black w-75">
            {observacoes}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <span>
            RD - Relatório Descritivo | T - Trimestre | M.F - Média Final | T.F
            - Frequência | * - Componente curricular de matrícula facultativa
          </span>
        </div>

        <div className="signature-section">
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>SECRETÁRIO(A) ESCOLAR</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>DIREÇÃO</span>
          </div>
          <div className="signature-field">
            <div className="signature-line"></div>
            <span>INSPETOR(A) ESCOLAR</span>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FichaIndividualInfantil;
