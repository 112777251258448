import React, { useEffect, useState } from 'react';
import BaseBilingue from '../BaseBilingue';
import { listarInscricoesFiltroBilingue, atualizarInscricaoBilingue } from '../../../../services/formulariosService';
import BilingueInscricoesFilter from '../components/BilingueInscricoesFilter';
import BilingueInscricoesTable from '../components/BilingueInscricoesTable';
import BilingueInscricoesAtualizar from '../components/BilingueInscricoesAtualizar';
import urlsBilingue from '../UrlsBilingue';
import { toast } from 'react-toastify';
import { set } from 'date-fns';
import { listaAnexo } from '../../../../services/processos/remanejamento';

const BilingueInscricoesPage = () => {

    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);

  const [filtros, setFiltros] = useState({
    tipoFormulario: '',
    redeEnsino: '',
    escola: '',
    polo: '',
    nomeCompleto: '',
    dataNascimento: null,
    cpf: '',
    uf: '',
    cidade: '',
    nomeResponsavel: '',
    rgResponsavel: '',
    cpfResponsavel: '',
    telefoneResponsavel: '',
    emailResponsavel: '',
    tipoDeficiencia: '',
    atendimentoEspecializado: '',
    possuiAnexo: false,
    status: '',
    skip: skip, // Pagination: starts at 0
    limit: limit, // Items per page
  });

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState('');
  const [observacao, setObservacao] = useState('');
  const [totalItems, setTotalItems] = useState(0);
 

  // Fetch data function to be reused
  const fetchData = async () => {
    const formattedDate = filtros.dataNascimento
      ? filtros.dataNascimento.toISOString().split('T')[0]
      : null;

    const payload = { ...filtros, dataNascimento: formattedDate };

    const response = await listarInscricoesFiltroBilingue(payload);
    setData(response?.data || []);
    setTotalItems(response?.total || 0); // Total items for pagination
  };

  // Fetch data whenever 'filtros' or pagination (skip, limit) changes
  useEffect(() => {
    console.log('Fetching data with filters:', filtros);
    console.log('Skip:', skip);
    console.log('Limit:', limit);
    fetchData();
  }, [filtros, skip, limit]);

  // Handle updating the filters and resetting pagination
  const handleSubmit = () => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      skip: 0, // Reset to first page on new filter submit
    }));
  };

  const handleAnexo = async (row) => {
    try {
      debugger;
  
      // Recebe o arquivo da função listaAnexo (agora como Blob)
      const arquivoBlob = await listaAnexo(row.original.anexoId);
  
      // Gera uma URL para o Blob
      const blobUrl = URL.createObjectURL(arquivoBlob);

      const extensao = arquivoBlob.type;
  
      // Verificar a extensão e abrir o arquivo em uma nova aba
      if (extensao === "application/pdf") {
        const pdfWindow = window.open(blobUrl, "_blank");
        if (!pdfWindow) {
          alert("Permita pop-ups para visualizar o PDF.");
        }
      } else if (extensao === "image/png") {
        const imgWindow = window.open(blobUrl, "_blank");
        if (!imgWindow) {
          alert("Permita pop-ups para visualizar a imagem.");
        }
      } else {
        // Para outros tipos de arquivos, abre uma nova aba
        const newWindow = window.open(blobUrl, "_blank");
        if (!newWindow) {
          alert("Permita pop-ups para visualizar o arquivo.");
        }
      }
  
      // Revogar a URL após abrir o arquivo para liberar memória
      URL.revokeObjectURL(blobUrl);
  
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  // Handle limit change
  const handleLimitChange = (newLimit) => {
        // setLimit(newLimit);
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            limit: newLimit,
        }));
  };

    // Handle skip change
    const handleSkipChange = (newSkip) => {
        // setSkip(newSkip);
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            skip: newSkip,
        }));
    }

  const handleView = (row) => {
    window.open(`${urlsBilingue.formularioVisualizacao}${row.original.id}`, '_blank');
  };

  const handleUpdate = (row) => {
    setSelectedRow(row.original);
    setStatus(row.original.status || ''); // Set default status
    setObservacao(row.original.observacao || ''); // Set default observação
    setOpenModal(true); // Open the modal
  };



  const handleSave = async () => {
    var response = await atualizarInscricaoBilingue(selectedRow.id, status, observacao);
    if (response.statusCode === 200) {
      toast.success('Inscrição atualizada com sucesso!');
      setOpenModal(false);
      fetchData(); // Refresh the data after saving
    } else {
      toast.error('Erro ao atualizar inscrição');
    }
  };

  const handleClose = () => {
    setOpenModal(false); // Close the modal without saving
  };

  return (
    <BaseBilingue>
      <BilingueInscricoesFilter filtros={filtros} setFiltros={setFiltros} handleSubmit={handleSubmit} />
      <div style={{ marginBottom: '20px' }}></div>
      <BilingueInscricoesTable 
        data={data} 
        handleView={handleView} 
        handleUpdate={handleUpdate} 
        handleAnexo={handleAnexo} 
        totalItems={totalItems}
        limit={filtros.limit}
        setLimit={(newLimit) => handleLimitChange(newLimit)} // Handle limit change properly
        skip={filtros.skip}
        setSkip={(newSkip) => handleSkipChange(newSkip)}
      />

      {/* Modal component */}
      <BilingueInscricoesAtualizar
        open={openModal}
        onClose={handleClose}
        onSave={handleSave}
        selectedRow={selectedRow}
        status={status}
        setStatus={setStatus}
        observacao={observacao}
        setObservacao={setObservacao}
      />
    </BaseBilingue>
  );
};

export default BilingueInscricoesPage;
