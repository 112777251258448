import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { postLogin } from '../../services/servidores';
import { Types as UserTypes } from '../../store/ducks/user';
import { loginAluno, loginResponsavel } from '../../services/familia-conectada';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Divider
} from '@mui/material';
import { color, styled } from '@mui/system';

const LoginContainer = styled('div')({
  display: 'flex',
  height: '100vh',
});

const LoginImage = styled('div')({
  flex: 1,
  backgroundImage: `url(/assets/images/fundo/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const LoginForm = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '2rem',
});

const Header = styled(Box)({
  textAlign: 'center',
  marginBottom: '2rem',
});

const LogoPrefeitura = styled('img')({
  width: '120px',
  marginBottom: '1rem',
});

const PrefeituraText = styled(Typography)({
  fontSize: '1.2rem',
  fontWeight: 'bold',
});

const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const { mutate, isLoading } = useMutation(postLogin, {
    onSuccess: (data) => {
      if (data.statusCode === 404) {
        return toast.error(data.message);
      }
      dispatch({ type: UserTypes.PROFILE, payload: { user: data.data } });
      setTimeout(() => {
        navigate('/modulos'); // Redireciona para os módulos
      }, 500);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar fazer login, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { handleSubmit, control, formState: { errors }, watch } = useForm({
    defaultValues: {
      email: '',
      password: '',
      system: '' // Adiciona o campo para o sistema
    }
  });

  const selectedSystem = watch('system');

  const handleLoginServidor = async ({ email, password }) => {
    mutate({ login: email.toUpperCase(), senha: password });
  };

  const handleLoginResponsavel = async () => {
    const email = watch('email');
    const password = watch('password');
    
    try {
      const response = await loginResponsavel({ cpf: email, dataNascimento: password });

      if (response?.data?.statusCode === 200) {
        localStorage.setItem('aluno', JSON.stringify(response?.data?.data[0]));
        localStorage.setItem('alunos', JSON.stringify(response?.data?.data));
        return navigate('familia-conectada/responsavel');
      }
      if (response?.response?.data?.statusCode === 400) {
        return toast.warn('CPF ou Data de nascimento inválidos.');
      }
      if (response?.response?.data?.statusCode === 404) {
        return toast.error('Aluno não foi encontrado.');
      }
      
    } catch (error) {
      return toast.error('Ocorreu algum erro inesperado');
    }
  };

  const handleLoginAluno = async () => {
    const email = watch('email');
    const password = watch('password');
    
    try {
      const response = await loginAluno({ cpf: email, dataNascimento: password });

      if (response?.data?.statusCode === 200) {
        localStorage.setItem('aluno', JSON.stringify(response?.data?.data));
        return navigate('familia-conectada/aluno');
      }
      if (response?.response?.data?.statusCode === 400) {
        return toast.warn('CPF ou Data de nascimento inválidos.');
      }
      if (response?.response?.data?.statusCode === 404) {
        return toast.error('Aluno não foi encontrado.');
      }
      
    } catch (error) {
      return toast.error('Ocorreu algum erro inesperado');
    }
  };

  const handleLogin = (data) => {
    debugger
    if (selectedSystem === 'SERVIDOR') {
      handleLoginServidor(data);
    } else if (selectedSystem === 'RESPONSÁVEL') {
      handleLoginResponsavel();
    } else if (selectedSystem === 'ALUNO') {
      handleLoginAluno();
    } else {
      toast.error('Selecione um sistema válido.');
    }
  };

  return (
    <LoginContainer>
      <LoginImage />
      <LoginForm>
        <Header>
          <LogoPrefeitura
            src={`/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png`}
            alt="Logo Prefeitura"
          />
          <PrefeituraText>{process.env.REACT_APP_PREFEITURA}</PrefeituraText>
          <PrefeituraText>SECRETARIA DE EDUCAÇÃO</PrefeituraText>
          <p className="auth-description">
          Faça login na sua conta e continue para o painel.
        </p>
        </Header>
        <Typography variant="h4" component="h2" textAlign="center" marginBottom="2rem">
          Login
        </Typography>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="system-label">Escolher Sistema</InputLabel>
                <Controller
                  name="system"
                  control={control}
                  render={({ field }) => (
                    <Select labelId="system-label" {...field} label="Escolher Sistema">
                      <MenuItem selected={true} value="SERVIDOR">SERVIDOR</MenuItem>
                      <MenuItem value="RESPONSÁVEL">RESPONSÁVEL</MenuItem>
                      <MenuItem value="ALUNO">ALUNO</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="E-mail/Usuário"
                    placeholder="example@sistema.com"
                    {...field}
                  />
                )}
              />
              {errors.email && <p className="text-danger">Digite o seu e-mail.</p>}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="password"
                    label="Senha"
                    placeholder="******"
                    {...field}
                  />
                )}
              />
              {errors.password && <p className="text-danger">Digite sua senha.</p>}
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Entrando...' : 'Fazer Login'}
            </Button>
          </Box>
        </form>
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="body2" textAlign="left">
          Sistema de Gestão do Ecossistema Educacional | Versão - 10.02.2088
        </Typography>
        <Typography variant="body2" textAlign="left">
          Todos os dados são de propriedade do cliente. Não faça uso indevido e
          nem mesmo divulgar/vender as informações no qual tem acesso.
        </Typography>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
