export const mock = [
  {
    aluno: {
      nome: "Nome do Aluno",
      dataNascimento: "2000-01-01",
      pai: "Nome do Pai",
      mae: "Nome da Mãe",
      naturalidade: "Cidade",
      uf: "RJ",
      sexo: "F",
    },
    escolaridade: {
      ano: 2,
      turma: "A",
      turno: "Manhã",
      numero: 1,
      anoLetivo: 2023,
    },
    componentesCurriculares: [
      {
        componente: "Língua Portuguesa",
        notas: {
          primeiroTrimestre: "R. D.",
          segundoTrimestre: "",
          terceiroTrimestre: "",
          mediaFinal: 0.0,
        },
        faltas: {
          primeiroTrimestre: 0,
          segundoTrimestre: 0,
          terceiroTrimestre: 0,
          totalFaltas: 0,
        },
        horasAulas: {
          primeiroTrimestre: 0,
          segundoTrimestre: 0,
          terceiroTrimestre: 0,
          total: 0,
        },
      },
      {
        componente: "Matemática",
        notas: {
          primeiroTrimestre: "R. D.",
          segundoTrimestre: "",
          terceiroTrimestre: "",
          mediaFinal: 0.0,
        },
        faltas: {
          primeiroTrimestre: 0,
          segundoTrimestre: 0,
          terceiroTrimestre: 0,
          totalFaltas: 0,
        },
        horasAulas: {
          primeiroTrimestre: 0,
          segundoTrimestre: 0,
          terceiroTrimestre: 0,
          total: 0,
        },
      },
      // Adicionar outros componentes curriculares
    ],
    totalGeral: {
      faltas: 0,
      horasAulas: 0,
    },
    resultadoFinal: {
      frequencia: 98,
      cargaHoraria: 0,
      diasLetivos: 201,
    },
    data: "2023-12-01",
  },
];
