import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import Breadcrumb from "../../../components/breadcrumb";
import { useForm } from "react-hook-form";
import FormInput from "../../../components/formInput";
import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import { LocationApi } from "../../../services/external/cep";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  adicionarServidor,
  listarServidor,
  editarServidor,
  listarUnidadesServidor,
  adicionarUnidadeServidor,
  deletarUnidadeServidor,
  adicionarDocumento,
  listarDocumentos,
  deletarDocumento
} from "../../../services/servidores";
import { getUnidades } from "../../../services/unidades";
import { useQuery } from "react-query";
import { store } from "../../../../src/store/index";
import Table from "../../../components/table";
import moment from "moment";
import { getServidoresCargos } from "../../../services/cargos";
import { Button } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { listaAnexo } from "../../../services/processos/remanejamento";

const currentUser = store.getState()["user"]["user"];
const ServidoresAdicionarPage = (gestao) => {
  const { id } = useParams();
  const currentDate = moment().format("YYYY-MM-DD");
  const { state } = useLocation();
  const navigate = useNavigate();
  const [unidadeData, setUnidadesData] = useState([]);
  const [nomeUsuario, setNomeUsuario] = useState("");

  const [generos, setGeneros] = useState([]);
  const [cores, setCores] = useState([]);
  const [nacionalidades, setNacionalidades] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [escolaridades, setEscolaridades] = useState([]);
  const [tiposDeficiencias, setTiposDeficiencias] = useState([]);
  const [orgaoEmissorRg, setOrgaoEmissorRg] = useState([]);
  const [servidoresCargos, setServidoresCargos] = useState([]);
  const [regimesServidores, setRegimesServidores] = useState([]);
  const [escolhaBinaria, setEscolhaBinaria] = useState([]);
  const [selectedRegimeDescription, setSelectedRegimeDescription] =
    useState("");

  const [cargosData, setCargosData] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(2000);
  const [skip, setSkip] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [ativo, setAtivo] = useState(true);
  const [cargo, setCargo] = useState(0);
  const [funcao, setFuncao] = useState(0);

  const [documents, setDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentType, setDocumentType] = useState("");

  const habilitaExcluir = gestao.gestao ? true : false;
  const habilitaVisualizar = gestao.gestao ? true : true;
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";

  const documentTypes = [
    { value: "ATESTADO", label: "ATESTADO" },
    { value: "CARTEIRA_VACINACAO", label: "CARTEIRA DE VACINAÇÃO" },
    { value: "CERTIDAO_NASCIMENTO", label: "CERTIDÃO DE NASCIMENTO" },
    { value: "CPF", label: "CPF" },
    { value: "DECLARACAO_ESCOLAR", label: "DECLARAÇÃO ESCOLAR" },
    { value: "HISTORICO_ESCOLAR", label: "HISTÓRICO ESCOLAR" },
    { value: "RG", label: "RG" },
    { value: "TITULO_DE_ELEITOR", label: "TÍTULO DE ELEITOR" },
    { value: "COMPROVANTE_DE_RESIDENCIA", label: "COMPROVANTE DE RESIDÊNCIA" },
    { value: "CERTIFICADO_DE_CONCLUSAO", label: "CERTIFICADO DE CONCLUSÃO" },
    { value: "DIPLOMA", label: "DIPLOMA" },
    { value: "CARTEIRA_DE_TRABALHO", label: "CARTEIRA DE TRABALHO" },
    { value: "CERTIFICADO_DE_RESERVISTA", label: "CERTIFICADO DE RESERVISTA" },
    { value: "CERTIDAO_DE_CASAMENTO", label: "CERTIDÃO DE CASAMENTO" },
    { value: "CARTAO_DO_SUS", label: "CARTÃO DO SUS" },
    { value: "PIS_PASEP", label: "PIS/PASEP" },
    { value: "CARTAO_DE_BENEFICIOS", label: "CARTÃO DE BENEFÍCIOS" }
  ];


  const sortedDocumentTypes = documentTypes.sort((a, b) =>
    a.label.localeCompare(b.label)
  );



  function extrairPrimeiroUltimoNome(nomeCompleto) {
    const partes = nomeCompleto.split(" ");
    if (partes.length >= 2) {
      const primeiroNome = partes[0];
      const ultimoNome = partes[partes.length - 1];

      setNomeUsuario(primeiroNome + "." + ultimoNome);
      // return { primeiroNome, ultimoNome };
    } else {
      setNomeUsuario(nomeCompleto);
    }
  }

  const handleRegimeChange = (event) => {
    const selectedOption = regimesServidores.find(
      (entry) => entry.id === parseInt(event.target.value)
    );
    setSelectedRegimeDescription(
      selectedOption ? selectedOption.description : ""
    );
  };

  const permissoesGestao = async (moduloGestao) => {
    setValue("moduloGestao", moduloGestao);
    setValue("moduloGestaoGrupoAcademico", moduloGestao);
    setValue("moduloGestaoGrupoGestaoEducacional", moduloGestao);
    setValue("moduloGestaoGrupoDiario", moduloGestao);
  };

  const permissoesGestaoUnidades = async (moduloGestaoUnidades) => {
    setValue("moduloGestaoUnidades", moduloGestaoUnidades);
    setValue("moduloGestaoUnidadesGrupoAcademico", moduloGestaoUnidades);
    setValue(
      "moduloGestaoUnidadesGrupoGestaoEducacional",
      moduloGestaoUnidades
    );
    setValue("moduloGestaoUnidadesGrupoDiario", moduloGestaoUnidades);
    setValue("moduloGestaoUnidadesGrupoTransferencias", moduloGestaoUnidades);
  };

  const permissoesDocente = async (moduloDocente) => {
    setValue("moduloDocente", moduloDocente);
    setValue("moduloDocenteGrupoAcademico", moduloDocente);
    setValue("moduloDocenteGrupoGestaoEducacional", moduloDocente);
    setValue("moduloDocenteGrupoDiario", moduloDocente);
  };

  const permissoesAdministrativo = async (moduloAdministrativo) => {
    setValue("moduloAdministrativo", moduloAdministrativo);
    setValue("moduloAdministrativoTransporte", moduloAdministrativo);
  };

  const { data } = useQuery("listarServidor", () => id && listarServidor(id), {
    retry: true,
    enabled: true,
  });

  const { refetch: refetchUnidadesServidor } = useQuery(
    "listarUnidadesServidor",
    () => id && listarUnidadesServidor(id),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        setUnidadesData(data);
      },
    }
  );

  const { refetch: refetchCargos } = useQuery(
    "getServidoresCargos",
    () => getServidoresCargos(ativo, skip, limit, descricao),
    {
      onSuccess: (data) => {
        setCargosData(data?.data);
        setTotal(data?.total);
        setLimit(data?.limit);
        setSkip(data?.skip);
      },
      enabled: true,
      retry: true,
    }
  );

  const { data2, refetch: refetchDocumentos, isLoading } = useQuery(
    "getServidoresDocumentos", () => id !== undefined && listarDocumentos(id), {
    retry: false,
    enabled: true,

    onSuccess: async (data) => {
      console.log(data?.data)
      setDocuments(data?.data);
    },
  });

  function formatDate(inputDate) {
    if (!inputDate) {
      return ""; // Return an empty string for null or undefined input
    }

    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (process.env.REACT_APP_SECRETARIA_CIDADE === "ARARUAMA") {
      import("../pickersAraruama").then((mod) => {
        setGeneros(mod.generos);
        setCores(mod.cores);
        setNacionalidades(mod.nacionalidades);
        setEstadoCivil(mod.estadoCivil);
        setEscolaridades(mod.escolaridades);
        setTiposDeficiencias(mod.tiposDeficiencias);
        setOrgaoEmissorRg(mod.orgaoEmissorRg);
        setServidoresCargos(mod.servidoresCargos);
        setRegimesServidores(mod.regimesServidores);
        setEscolhaBinaria(mod.escolhaBinaria);
      });
    } else {
      import("../pickersSaoPedro").then((mod) => {
        setGeneros(mod.generos);
        setCores(mod.cores);
        setNacionalidades(mod.nacionalidades);
        setEstadoCivil(mod.estadoCivil);
        setEscolaridades(mod.escolaridades);
        setTiposDeficiencias(mod.tiposDeficiencias);
        setOrgaoEmissorRg(mod.orgaoEmissorRg);
        setServidoresCargos(mod.servidoresCargos);
        setRegimesServidores(mod.regimesServidores);
        setEscolhaBinaria(mod.escolhaBinaria);
      });
    }
  }, []);

  useEffect(() => {
    if (data && id) {
      // DADOS PESSOAIS
      setValue("nomeCompleto", data?.nomeCompleto);
      setValue("nascimento", formatDate(data?.nascimento));
      setValue("estadoNascimento", data?.estadoNascimento);
      setValue("sexo", data?.sexo);
      setValue("cor", data?.cor);
      setValue("nacionalidade", data?.nacionalidade);
      setValue("estadoCivil", data?.estadoCivil);
      setValue("escolaridade", data?.escolaridade);
      setValue("filiacao1", data?.filiacao1);
      setValue("filiacao2", data?.filiacao2);
      setValue("deficiencia", data?.deficiencia);
      // DADOS CONTATO
      setValue("email", data?.email);
      setValue("telefoneCelular", data?.telefoneCelular || "");
      setValue("telefoneFixo", data?.telefoneFixo || "");
      setValue("cep", data?.cep || "");
      setValue("logradouro", data?.logradouro);
      setValue("numero", data?.numero);
      setValue("bairro", data?.bairro);
      setValue("complemento", data?.complemento);
      setValue("estado", data?.estado);
      setValue("cidade", data?.cidade);
      //DADOS DOCUMENTO
      setValue("cpf", data?.cpf);
      setValue("rg", data?.rg);
      setValue("orgaoEmissorRg", data?.orgaoEmissor);
      setValue("emissaoRg", formatDate(data?.dataEmissaoRG));
      setValue("nis", data?.nis);
      setValue("numeroTituloEleitor", data?.numeroTituloEleitoral);
      setValue("zonaEleitoral", data?.zonaEleitoral);
      setValue("secaoEleitoral", data?.secaoEleitoral);
      // DADOS CONTRATO
      setValue("contratoNumeroMatricula", data?.numeroContrato);
      setValue("contratoNomeacao", formatDate(data?.dataNomeacao));
      setValue("contratoPosse", formatDate(data?.dataPosse));
      setValue("cargo", data?.cargo); // setando estado para puxar do banco
      setValue("contratoUnidadeLotacao", data?.departamento);
      setValue("funcao1", data?.funcao1); // aguardar o back end pra modificar
      setValue("reabilitado", data?.reabilitado);

      // DADOS REGIME
      setValue("regime", data?.regime);
      setSelectedRegimeDescription(
        regimesServidores.find((entry) => entry.id === data?.regime)
          ?.description
      );
      setValue("servidorPermuta", data?.servidorPermuta);
      setValue("cidadePermuta", data?.cidadePermuta);

      //DADOS ACESSO
      setValue("moduloGestao", data?.moduloGestao);
      setValue("moduloDocente", data?.moduloDocente);
      setValue("moduloGestaoUnidades", data?.moduloGestaoUnidades);
      setValue("usuarioSenha", data?.usuarioSenha);
      setValue("confirmarSenha", data?.usuarioSenha);

      // DADOS ACESSO CONFIGURAÇÃO
      setValue("usuarioPodeAcessarSistema", data?.usuarioPodeAcessarSistema);
      setValue("usuarioAdministrador", data?.usuarioAdministrador);
      setValue("moduloConfiguracao", data?.moduloConfiguracao);
      setValue("moduloAdministrativo", data?.moduloAdministrativo);

      //DADOS MÓDULO GESTÃO
      setValue("moduloGestaoGrupoAcademico", data?.moduloGestaoGrupoAcademico);
      setValue(
        "moduloGestaoGrupoGestaoEducacional",
        data?.moduloGestaoGrupoGestaoEducacional
      );
      setValue("moduloGestaoGrupoDiario", data?.moduloGestaoGrupoDiario);
      setValue("moduloGestaoFrequenciaServidores", data?.moduloGestaoFrequenciaServidores);

      //DADOS MÓDULO GESTÃO DE UNIDADES
      setValue(
        "moduloGestaoUnidadesGrupoAcademico",
        data?.moduloGestaoUnidadesGrupoAcademico
      );
      setValue(
        "moduloGestaoUnidadesGrupoGestaoEducacional",
        data?.moduloGestaoUnidadesGrupoGestaoEducacional
      );
      setValue(
        "moduloGestaoUnidadesGrupoDiario",
        data?.moduloGestaoUnidadesGrupoDiario
      );
      setValue(
        "moduloGestaoUnidadesGrupoTransferencias",
        data?.moduloGestaoUnidadesGrupoTransferencias
      );

      setValue("moduloGestaoUnidadesFrequenciaServidores", data?.moduloGestaoUnidadesFrequenciaServidores);

      //DADOS MÓDULO DOCENTE
      setValue(
        "moduloDocenteGrupoAcademico",
        data?.moduloDocenteGrupoAcademico
      );
      setValue("moduloDocenteGrupoDiario", data?.moduloDocenteGrupoDiario);
      setValue(
        "moduloGestaoUnidadesGrupoDiario",
        data?.moduloGestaoUnidadesGrupoDiario
      );
      setValue(
        "moduloDocenteGrupoGestaoEducacional",
        data?.moduloDocenteGrupoGestaoEducacional
      );

      //DADOS MÓDULO FAMÍLIA CONECTADA
      setValue(
        "moduloGestaoUnidadesGrupoFamiliaConectada",
        data?.moduloGestaoUnidadesGrupoFamiliaConectada
      );

      setValue("moduloBiblioteca", data?.moduloBiblioteca);

      //MÓDULO ADMINISTRATIVO
      setValue(
        "moduloAdministrativoTransporte",
        data?.moduloAdministrativoTransporte
      );

      setCargo(data?.cargo);
      setFuncao(data?.funcao1);
    }
  }, [data, id, navigate]);

  function zipcodeMask(value) {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d{3})$/, "$1-$2");
    return value;
  }

  const { current: locationApi } = useRef(new LocationApi());
  const { current: controller } = useRef(new AbortController());

  const { data: unidades, isLoading: loadingUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      nomeCompleto: "",
      nascimento: "",
      estadoNascimento: "",
      sexo: 0,
      cor: 0,
      nacionalidade: 0,
      estadoCivil: 0,
      escolaridade: 0,
      filiacao1: "",
      filiacao2: "",
      deficiente: 0,
      deficiencia: 0,
      email: "",
      telefoneCelular: "",
      telefoneFixo: "",
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      estado: "",
      cidade: "",
      cpf: "",
      rg: "",
      emissaoRg: null,
      contratoNumeroMatricula: "",
      contratoNomeacao: null,
      contratoPosse: null,
      contratoCargo: 0,
      contratoUnidadeLotacao: 0,
      usuarioLogin: nomeUsuario,
      usuarioSenha: "",
      usuarioPodeAcessarSistema: 0,
      moduloGestao: 0,
      moduloGestaoUnidades: 0,
      moduloAdministrativo: 0,
      moduloDocente: 0,
      moduloConfiguracao: 0,
      moduloGestaoGrupoGestaoEducacional: 0,
      moduloGestaoGrupoAcademico: 0,
      moduloGestaoGrupoDiario: 0,
      moduloGestaoUnidadesGrupoGestaoEducacional: 0,
      moduloGestaoUnidadesGrupoAcademico: 0,
      moduloGestaoUnidadesGrupoDiario: 0,
      moduloGestaoUnidadesGrupoTransferencias: 0,
      moduloDocenteGrupoGestaoEducacional: 0,
      moduloDocenteGrupoAcademico: 0,
      moduloDocenteGrupoDiario: 0,
      moduloAdministrativoTransporte: 0,
      moduloGestaoUnidadesGrupoFamiliaConectada: 0,
      funcao1: 0,
      reabilitado: 0,
      moduloGestaoFrequenciaServidores: 0,
      moduloGestaoUnidadesFrequenciaServidores: 0,
    },
  });

  const onSubmit = async (values) => {
    // if (values.usuarioSenha != values.confirmarSenha){
    //     return toast.error('As senhas não coincidem.')
    // }

    values.usuarioLogin = nomeUsuario;
    values.nomeCompleto = values.nomeCompleto.toUpperCase();

    if (id) {
      try {
        values.contratoCargo = values.cargo;
        values.contratoNomeacao =
          values.contratoNomeacao === ""
            ? currentDate
            : values.contratoNomeacao;
        values.contratoPosse =
          values.contratoPosse === "" ? currentDate : values.contratoPosse;
        if (values.emissaoRg == null || values.emissaoRg == "") {
          values.emissaoRg = currentDate;
        }

        await editarServidor(id, values);

        toast.success("Servidor editado com sucesso.");
      } catch (error) {
        // Handle errors if needed
        toast.error("Ocorreu um erro ao editar o servidor.");
        console.error("Ocorreu um erro ao editar o servidor.", error);
      }
    } else {
      try {
        var msgRetorno = verificaDadosServidor(values);

        values.contratoCargo = values.cargo;

        values.regime = values.regime === "" ? "0" : values.regime;

        values.orgaoEmissorRg =
          values.orgaoEmissorRg === "" ? "0" : values.orgaoEmissorRg;

        values.emissaoRg =
          values.emissaoRg === "" ? currentDate : values.emissaoRg;

        values.contratoNomeacao =
          values.contratoNomeacao === ""
            ? currentDate
            : values.contratoNomeacao;

        values.contratoPosse =
          values.contratoPosse === "" ? currentDate : values.contratoPosse;

        if (msgRetorno != "ok") {
          toast.error("O campo " + msgRetorno + " é obrigatório");
        } else {
          await adicionarServidor(values);

          toast.success("Servidor criado com sucesso.");
        }
      } catch (error) {
        // Handle errors if needed
        toast.error("Ocorreu um erro ao criar o servidor.");
        console.error("Ocorreu um erro ao criar o servidor.", error);
      }
    }

    function verificaDadosServidor(data) {
      const validacoes = [
        { campo: "nomeCompleto", mensagem: "Nome Completo" },
        { campo: "nascimento", mensagem: "Data de Nascimento" },
        { campo: "cpf", mensagem: "CPF" },
        { campo: "rg", mensagem: "RG" },
        { campo: "cargo", mensagem: "Cargo" },
        { campo: "contratoUnidadeLotacao", mensagem: "Unidade de Lotação" },
      ];

      for (const validacao of validacoes) {
        if (!data[validacao.campo] || data[validacao.campo].trim() === "") {
          return validacao.mensagem;
        }
      }

      return "ok";
    }
  };

  const deletarUnidadeServidorFunction = async (unidadeId) => {
    try {
      // Assuming deletarUnidadeServidor is an asynchronous function
      await deletarUnidadeServidor(unidadeId, id);

      toast.success("Unidade removida do servidor com sucesso.");
      refetchUnidadesServidor();
    } catch (error) {
      toast.error("Ocorreu um erro ao remover a unidade do servidor.");
      console.error("Error deleting unidade from servidor:", error);
      // Handle the error if needed
    }
  };

  const adicionarUnidadeServidorFunction = async (unidadeId) => {
    try {
      const body = {
        servidorId: parseInt(id),
        unidadeId: parseInt(unidadeId),
      };

      // Assuming adicionarUnidadeServidor is an asynchronous function
      await adicionarUnidadeServidor(body);

      toast.success("Servidor alocado na unidade com sucesso.");
      refetchUnidadesServidor();
    } catch (error) {
      toast.error("Ocorreu um erro ao alocar o servidor na unidade.");
      console.error("Error adding servidor to unidade:", error);
      // Handle the error if needed
    }
  };

  useEffect(() => {
    const cep = zipcodeMask(watch("cep")).replace(/\D/, "");
    handlAutoCompleteForm(cep);
  }, [watch("cep")]);

  useEffect(() => {
    extrairPrimeiroUltimoNome(watch("nomeCompleto"));
  }, [watch("nomeCompleto")]);

  const handlAutoCompleteForm = useCallback(
    async (cep) => {
      if (cep.length < 8) return;
      try {
        const data = await locationApi.searchByCep(cep, controller.signal);
        let cidade = data?.data.data.cidade;
        let uf = data?.data.data.uf;
        let bairro = data?.data.data.bairro;
        let logradouro = data?.data.data.logradouro;

        if (cep === data?.data.data.cep) {
          return;
        }

        if (data.status === 400) {
          toast.warn(data?.data.message);
        }

        setValue("bairro", bairro, {
          shouldValidate: true,
        });
        setValue("cidade", cidade, {
          shouldValidate: true,
        });
        setValue("estado", uf, {
          shouldValidate: true,
        });
        setValue("logradouro", logradouro, {
          shouldValidate: true,
        });
      } catch (error) {
        // toast.error('Não foi possível carregar o endereço, cep não encontrado na base de dados do correio.');
      }
    },
    [setValue]
  );

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("arquivo", selectedFile);
      formData.append("servidorId", id);
      formData.append("tipo", documentType);
      await adicionarDocumento(formData);
      // setDocuments([...documents, newDocument]);

      // await refetchDocumentos()
      toast.success("Documento enviado com sucesso.");
      document.getElementById("fileUpload").value = "";
      setSelectedFile(null);
      setDocumentType("");
      await refetchDocumentos();
    } else {
      toast.error("Selecione um arquivo para enviar.");
    }
  };

  const handleDelete = async (id) => {
    debugger
    await deletarDocumento(id);
    refetchDocumentos();
  };

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ marginTop: 0 }}>
          {habilitaExcluir && (
            <Dropdown.Item
              className="dropdown-item-danger d-flex"
              onClick={() => handleDelete(row.original.arquivoId)}
            >
              <i className="material-icons pe-2">delete</i> Excluir
            </Dropdown.Item>
          )}
          {habilitaVisualizar && (
            <Dropdown.Item
              className="dropdown-item-secondary d-flex"
              onClick={() =>
                handleDownload(row.original.arquivoId, row.original.extensao)
              }
            >
              <i className="material-icons-two-tone pe-2">edit_note</i>{" "}
              Visualizar
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleDownload = async (arquivoId, extensao) => {
    try {

      // Recebe o arquivo da função listaAnexo (agora como Blob)
      const arquivoBlob = await listaAnexo(arquivoId);

      // Gera uma URL para o Blob
      const blobUrl = URL.createObjectURL(arquivoBlob);

      // Verificar a extensão e abrir o arquivo em uma nova aba
      if (extensao === "application/pdf") {
        const pdfWindow = window.open(blobUrl, "_blank");
        if (!pdfWindow) {
          alert("Permita pop-ups para visualizar o PDF.");
        }
      } else if (extensao === "image/png") {
        const imgWindow = window.open(blobUrl, "_blank");
        if (!imgWindow) {
          alert("Permita pop-ups para visualizar a imagem.");
        }
      } else {
        // Para outros tipos de arquivos, abre uma nova aba
        const newWindow = window.open(blobUrl, "_blank");
        if (!newWindow) {
          alert("Permita pop-ups para visualizar o arquivo.");
        }
      }

      // Revogar a URL após abrir o arquivo para liberar memória
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  const collumnsDocumentos = useMemo(() => [
    {
      Header: "ID",
      accessor: "arquivoId",
    },
    {
      Header: "Data de Envio",
      accessor: "dataCriacao",
      Cell: ({ row }) =>
        row.original.dataCriacao.split("T")[0].split("-").reverse().join("/") +
        " às " +
        row.original.dataCriacao.split("T")[1].split(".")[0],
    },
    {
      Header: "Tipo de Documento",
      accessor: "tipo",
    },
    {
      Header: "Usuário",
      accessor: "usuarioLogin",
    },
    {
      Header: "Tamanho",
      accessor: "tamanho",
      Cell: ({ value }) => {
        const sizeInKB = value / 1024;
        if (sizeInKB > 1000) {
          const sizeInMB = sizeInKB / 1024;
          return `${sizeInMB.toFixed(2)} MB`;
        } else {
          return `${sizeInKB.toFixed(2)} KB`;
        }
      },
    },
    {
      Header: "Extensão",
      accessor: "extensao",
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<VisibilityIcon />}
            onClick={() =>
              handleDownload(row.original.arquivoId, row.original.extensao)
            }
          >
            Visualizar
          </Button>

          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={() => handleDelete(row.original.arquivoId)}
            style={{ marginLeft: "10px" }} // Add space between buttons
          >
            Deletar
          </Button>
        </>
      ),
    },
  ]);

  const columnsUnidades = useMemo(
    () => [
      { Header: "#", accessor: "unidadeId" },
      { Header: "Nome", accessor: "unidadeNome" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {` `}
            <button
              className="btn btn-sm btn-danger"
              type="button"
              onClick={() => {
                const confirm = window.confirm(
                  "Tem certeza que deseja excluir essa alocação?"
                );
                if (confirm) {
                  deletarUnidadeServidorFunction(row.original.unidadeId);
                }
              }}
            >
              <i className="material-icons-two-tone">group_remove</i> excluir
            </button>
          </div>
        ),
      },
    ],
    [navigate]
  );

  return (
    <BaseComponent>
      <Breadcrumb
        title={"CADASTRO SERVIDOR"}
        itens={[BasePath, "Servidores", "Cadastro"]}
      />
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul
                    className="nav nav-tabs"
                    id="servidoresTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="dados-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#dados"
                        type="button"
                        role="tab"
                        aria-controls="dados"
                        aria-selected="true"
                      >
                        DADOS GERAIS
                      </button>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      style={{ display: id ? "block" : "none" }}
                    >
                      <button
                        className="nav-link"
                        id="unidades-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#unidades"
                        type="button"
                        role="tab"
                        aria-controls="unidades"
                        aria-selected="true"
                      >
                        UNIDADES DE EXERCÍCIO
                      </button>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      style={{ display: id ? "block" : "none" }}
                    >
                      <button
                        className="nav-link"
                        id="arquivos-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#arquivos"
                        type="button"
                        role="tab"
                        aria-controls="arquivos"
                        aria-selected="true"
                      >
                        DOCUMENTOS
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="dados"
                      role="tabpanel"
                      aria-labelledby="dados-tab"
                    >
                      <h4 style={{ marginTop: "20px", marginBottom: "1px" }}>
                        DADOS PESSOAIS
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="nomeCompleto"
                            label="Nome Completo"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="nascimento"
                            label="Data Nascimento"
                            type="date"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="estadoNascimento"
                            label="Estado Nascimento"
                            required={false}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Sexo</label>
                            <select
                              className="form-select"
                              id="sexo"
                              {...register("sexo", { required: false })}
                            >
                              {generos.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Raça/Cor</label>
                            <select
                              className="form-select"
                              id="cor"
                              {...register("cor", { required: false })}
                            >
                              {cores.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Nacionalidade</label>
                            <select
                              className="form-select"
                              id="nacionalidade"
                              {...register("nacionalidade", {
                                required: false,
                              })}
                            >
                              {nacionalidades.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Estado Civil</label>
                            <select
                              className="form-select"
                              id="estadoCivil"
                              {...register("estadoCivil", { required: false })}
                            >
                              {estadoCivil.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Grau de Escolaridade</label>
                            <select
                              className="form-select"
                              id="escolaridade"
                              {...register("escolaridade", { required: false })}
                            >
                              {escolaridades.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.description}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="filiacao1"
                            label="Filiação 1"
                            required={false}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="filiacao2"
                            label="Filiação 2"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="deficiente">Deficiente?</label>
                            <select
                              className="form-select"
                              id="deficiente"
                              {...register("deficiente", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {watch("deficiente") === 1 && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="deficiencia">
                                Deficiência
                                <span
                                  style={{ color: "red", marginLeft: "4px" }}
                                >
                                  *
                                </span>
                              </label>
                              <select
                                className="form-select"
                                id="deficiencia"
                                {...register("deficiencia", {
                                  valueAsNumber: true,
                                })}
                              >
                                <option></option>
                                {tiposDeficiencias?.map((tipoDeficiencia) => (
                                  <option
                                    key={tipoDeficiencia.id}
                                    value={tipoDeficiencia.id}
                                  >
                                    {tipoDeficiencia.description}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>

                      <h4 style={{ marginTop: "20px", marginBottom: "1px" }}>
                        DADOS CONTATO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="email"
                            label="Email"
                            type="email"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="telefoneCelular"
                            label="Telefone Celular"
                            mask="(99)99999-9999"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="telefoneFixo"
                            label="Telefone Fixo"
                            mask="(99)9999-9999"
                            required={false}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="cep"
                            label="CEP"
                            mask="99999-999"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="logradouro"
                            label="Logradouro"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="numero"
                            label="Número"
                            type="text"
                            required={false}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="bairro"
                            label="Bairro"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="complemento"
                            label="Complemento"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="estado"
                            label="Estado"
                            required={false}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="cidade"
                            label="Cidade"
                            required={false}
                          />
                        </div>
                      </div>

                      <h4 style={{ marginTop: "20px", marginBottom: "1px" }}>
                        DADOS DOCUMENTO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="cpf"
                            label="CPF"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="rg"
                            label="RG"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Orgão Emissor RG</label>
                            <select
                              className="form-select"
                              id="orgaoEmissorRg"
                              {...register("orgaoEmissorRg", {
                                required: false,
                              })}
                            >
                              {orgaoEmissorRg.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.acronym} - {entry.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="emissaoRg"
                            label="Data de Emissão do RG"
                            type="date"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="nis"
                            label="NIS"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="numeroTituloEleitor"
                            label="Número do Título de Eleitor"
                            required={false}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="zonaEleitoral"
                            label="Zona Eleitoral"
                            required={false}
                          />
                        </div>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="secaoEleitoral"
                            label="Seção Eleitoral"
                            required={false}
                          />
                        </div>
                      </div>

                      <h4 style={{ marginTop: "20px", marginBottom: "1px" }}>
                        DADOS DO CARGO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="contratoNumeroMatricula"
                            label="Número da Matrícula"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="contratoNomeacao"
                            label="Data da Nomeação"
                            required={false}
                            type="date"
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="contratoPosse"
                            label="Data da Posse"
                            required={false}
                            type="date"
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Cargo
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </label>
                            <select
                              className="form-select"
                              id="cargo"
                              value={cargo}
                              {...register("cargo", { required: false })}
                              onChange={async (e) => {
                                await setCargo(e.target.value);
                              }}
                            >
                              {cargosData?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.descricao}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Unidade de Lotação
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </label>
                            <select
                              className="form-select"
                              id="contratoUnidadeLotacao"
                              {...register("contratoUnidadeLotacao", {
                                required: true,
                              })}
                            >
                              <option value={0}></option>
                              {unidades?.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.nome}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Função</label>
                            <select
                              className="form-select"
                              id="funcao1"
                              value={funcao}
                              {...register("funcao1", { required: false })}
                              onChange={async (e) => {
                                await setFuncao(e.target.value);
                              }}
                            >
                              {cargosData?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.descricao}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Profissional Reabilitado?</label>
                            <select
                              className="form-select"
                              id="reabilitado"
                              {...register("reabilitado", { required: false })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <h4 style={{ marginTop: "20px", marginBottom: "1px" }}>
                        DADOS REGIME
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Regime</label>
                            <select
                              className="form-select"
                              id="regime"
                              {...register("regime", { required: false })}
                              onChange={handleRegimeChange}
                            >
                              {regimesServidores.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.description}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {selectedRegimeDescription === "PERMUTADO" && (
                          <>
                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="servidorPermuta"
                                label="Servidor Permuta"
                                required={false}
                              />
                            </div>

                            <div className="col-md-4">
                              <FormInput
                                register={register}
                                errors={errors}
                                atribute="cidadePermuta"
                                label="Cidade Permuta"
                                required={false}
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <h4 style={{ marginTop: "20px", marginBottom: "1px" }}>
                        DADOS DE ACESSO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="moduloGestao">Gestão?</label>
                            <select
                              className="form-select"
                              id="moduloGestao"
                              {...register("moduloGestao", {
                                required: false,
                                valueAsNumber: true,
                                readOnly:
                                  currentUser?.moduloGestao != 1 ? true : false,
                              })}
                              onChange={async (e) => {
                                await permissoesGestao(e.target.value);
                              }}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoUnidades">
                              Gestão de Unidades?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoUnidades"
                              {...register("moduloGestaoUnidades", {
                                required: false,
                                valueAsNumber: true,
                                readOnly:
                                  currentUser?.moduloGestao != 1 ? true : false,
                              })}
                              onChange={async (e) => {
                                await permissoesGestaoUnidades(e.target.value);
                              }}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="moduloDocente">Docente?</label>
                            <select
                              className="form-select"
                              id="moduloDocente"
                              {...register("moduloDocente", {
                                required: false,
                                valueAsNumber: true,
                                readOnly:
                                  currentUser?.moduloGestao != 1 ? true : false,
                              })}
                              onChange={async (e) => {
                                await permissoesDocente(e.target.value);
                              }}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="moduloAdministrativo">
                              Administrativo?
                            </label>
                            <select
                              className="form-select"
                              id="moduloAdministrativo"
                              {...register("moduloAdministrativo", {
                                required: false,
                                valueAsNumber: true,
                                readOnly:
                                  currentUser?.moduloGestao != 1 ? true : false,
                              })}
                              onChange={async (e) => {
                                await permissoesAdministrativo(e.target.value);
                              }}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="usuarioLogin"
                            label="Usuário"
                            placeholder={nomeUsuario}
                            required={false}
                            readOnly={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="usuarioSenha"
                            label="Senha"
                            required={false}
                            readOnly={
                              currentUser?.moduloGestao != 1 ||
                                currentUser?.servidorId == id
                                ? false
                                : true
                            }
                            type="password"
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="confirmarSenha"
                            label="Confirmar Senha"
                            required={false}
                            readOnly={
                              currentUser?.moduloGestao != 1 ||
                                currentUser?.servidorId == id
                                ? false
                                : true
                            }
                            type="password"
                          />
                        </div>
                      </div>

                      <h4 style={{ marginTop: "40px", marginBottom: "1px" }}>
                        MÓDULO ADMINISTRAÇÃO / CONFIGURAÇÃO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        {currentUser?.usuarioAdministrador == 1 && (
                          <>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="usuarioAdministrador">
                                  Usuário Administrador?
                                </label>
                                <select
                                  className="form-select"
                                  id="usuarioAdministrador"
                                  {...register("usuarioAdministrador", {
                                    required: false,
                                    readOnly:
                                      currentUser?.usuarioAdministrador != 1
                                        ? true
                                        : false,
                                  })}
                                >
                                  {escolhaBinaria.map((entry) => (
                                    <option
                                      key={entry.value}
                                      value={entry.value}
                                    >
                                      {entry.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloConfiguracao">
                              Módulo Configuração?
                            </label>
                            <select
                              className="form-select"
                              id="moduloConfiguracao"
                              {...register("moduloConfiguracao", {
                                required: false,
                                readOnly:
                                  currentUser?.usuarioAdministrador != 1
                                    ? true
                                    : false,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="usuarioPodeAcessarSistema">
                              Pode Acessar Sistema?
                            </label>
                            <select
                              className="form-select"
                              id="usuarioPodeAcessarSistema"
                              {...register("usuarioPodeAcessarSistema", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "20px",
                          color: "#2872ba",
                          textAlign: "left",
                        }}
                      >
                        <label>
                          <b>Parâmetros do Sistema: </b>Unidades, Escolaridades,
                          Turnos, Disciplinas, BNCC, Reponsáveis, Cargos,
                          Nacionalidades.
                        </label>
                      </div>

                      <h4 style={{ marginTop: "40px", marginBottom: "1px" }}>
                        MÓDULO GESTÃO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoGrupoGestaoEducacional">
                              Gestão Educacional?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoGrupoGestaoEducacional"
                              {...register(
                                "moduloGestaoGrupoGestaoEducacional",
                                { required: false, valueAsNumber: true }
                              )}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoGrupoAcademico">
                              Acadêmico?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoGrupoAcademico"
                              {...register("moduloGestaoGrupoAcademico", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoGrupoDiario">
                              Diário?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoGrupoDiario"
                              {...register("moduloGestaoGrupoDiario", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoFrequenciaServidores">
                              Frequência Servidores?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoFrequenciaServidores"
                              {...register("moduloGestaoFrequenciaServidores", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "20px",
                          color: "#2872ba",
                          textAlign: "left",
                        }}
                      >
                        <label>
                          <b>Gestão Educacional:</b> Dashboard, Notificações,
                          Servidores. | <b>Acadêmico:</b> Alunos, Turmas. |{" "}
                          <b>Diário:</b> Quadro de Horários, Diário de Classe.
                        </label>
                      </div>

                      <h4 style={{ marginTop: "40px", marginBottom: "1px" }}>
                        MÓDULO GESTÃO DE UNIDADES
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoUnidadesGrupoGestaoEducacional">
                              Gestão Educacional?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoUnidadesGrupoGestaoEducacional"
                              {...register(
                                "moduloGestaoUnidadesGrupoGestaoEducacional",
                                { required: false, valueAsNumber: true }
                              )}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoUnidadesGrupoAcademico">
                              Acadêmico?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoUnidadesGrupoAcademico"
                              {...register(
                                "moduloGestaoUnidadesGrupoAcademico",
                                { required: false, valueAsNumber: true }
                              )}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoUnidadesGrupoDiario">
                              Diário?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoUnidadesGrupoDiario"
                              {...register("moduloGestaoUnidadesGrupoDiario", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoUnidadesGrupoTransferencias">
                              Transferências?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoUnidadesGrupoTransferencias"
                              {...register(
                                "moduloGestaoUnidadesGrupoTransferencias",
                                { required: false, valueAsNumber: true }
                              )}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoFrequenciaServidores">
                              Frequência Servidores?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoUnidadesFrequenciaServidores"
                              {...register("moduloGestaoUnidadesFrequenciaServidores", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "20px",
                          color: "#2872ba",
                          textAlign: "left",
                        }}
                      >
                        <label>
                          <b>Gestão Educacional:</b> Dashboard, Notificações,
                          Servidores. | <b>Acadêmico:</b> Alunos, Turmas,
                          Matrículas. | <b>Diário:</b> Quadro de Horários,
                          Diário de Classe. | <b>Transferências:</b> Eviadas,
                          Recebidas.
                        </label>
                      </div>

                      <h4 style={{ marginTop: "40px", marginBottom: "1px" }}>
                        MÓDULO DOCENTE
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloDocenteGrupoGestaoEducacional">
                              Gestão Educacional?
                            </label>
                            <select
                              className="form-select"
                              id="moduloDocenteGrupoGestaoEducacional"
                              {...register(
                                "moduloDocenteGrupoGestaoEducacional",
                                { required: false, valueAsNumber: true }
                              )}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloDocenteGrupoAcademico">
                              Acadêmico?
                            </label>
                            <select
                              className="form-select"
                              id="moduloDocenteGrupoAcademico"
                              {...register("moduloDocenteGrupoAcademico", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloDocenteGrupoDiario">
                              Diário?
                            </label>
                            <select
                              className="form-select"
                              id="moduloDocenteGrupoDiario"
                              {...register("moduloDocenteGrupoDiario", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "20px",
                          color: "#2872ba",
                          textAlign: "left",
                        }}
                      >
                        <label>
                          <b>Gestão Educacional:</b> Dashboard, Calendário
                          Letivo. | <b>Acadêmico:</b> Alunos, Turmas. |{" "}
                          <b>Diário:</b> Quadro de Horários, Diário de Classe.
                        </label>
                      </div>

                      <h4 style={{ marginTop: "40px", marginBottom: "1px" }}>
                        MÓDULO FAMÍLIA CONECTADA
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloGestaoUnidadesGrupoFamiliaConectada">
                              Pode acessar o módulo?
                            </label>
                            <select
                              className="form-select"
                              id="moduloGestaoUnidadesGrupoFamiliaConectada"
                              {...register(
                                "moduloGestaoUnidadesGrupoFamiliaConectada",
                                { required: false, valueAsNumber: true }
                              )}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginTop: "20px",
                          color: "#2872ba",
                          textAlign: "left",
                        }}
                      >
                        <label>
                          <b>Gestão Educacional:</b> Dashboard, Calendário
                          Letivo. | <b>Acadêmico:</b> Alunos, Turmas. |{" "}
                          <b>Diário:</b> Quadro de Horários, Diário de Classe.
                        </label>
                      </div>

                      <h4 style={{ marginTop: "40px", marginBottom: "1px" }}>
                        MÓDULO ADMINISTRATIVO
                      </h4>
                      <hr
                        style={{
                          background: "#000",
                          color: "#000",
                          borderColor: "#00",
                          height: "1px",
                        }}
                      />

                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="moduloAdministrativoTransporte">
                              Transporte?
                            </label>
                            <select
                              className="form-select"
                              id="moduloAdministrativoTransporte"
                              {...register("moduloAdministrativoTransporte", {
                                required: false,
                                valueAsNumber: true,
                              })}
                            >
                              {escolhaBinaria.map((entry) => (
                                <option key={entry.value} value={entry.value}>
                                  {entry.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: 0,
                          marginTop: 20,
                        }}
                      >
                        <button
                          style={{ marginRight: 20 }}
                          type="button"
                          className="btn btn-warning"
                          onClick={() => navigate(-1)}
                        >
                          Voltar
                        </button>
                        <button
                          type="submit"
                          className="btn btn-success"
                        // disabled={!isValid}
                        >
                          Enviar
                        </button>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade show"
                      id="unidades"
                      role="tabpanel"
                      aria-labelledby="unidades-tab"
                    >
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Unidade
                              <span style={{ color: "red", marginLeft: "4px" }}>
                                *
                              </span>
                            </label>
                            <select
                              className="form-select"
                              id="unidade"
                              {...register("unidade", { required: false })}
                            >
                              {unidades?.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.nome}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="card-body text-end">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                              adicionarUnidadeServidorFunction(watch("unidade"))
                            }
                          >
                            Alocar
                          </button>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-12">
                          <Table
                            columns={columnsUnidades}
                            data={unidadeData ? unidadeData : []}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show"
                      id="arquivos"
                      role="tabpanel"
                      aria-labelledby="arquivos-tab"
                    >
                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="fileUpload" className="form-label">
                              UPLOAD DE DOCUMENTO
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="fileUpload"
                              accept=".pdf,.png"
                              onChange={handleFileChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="documentType" className="form-label">
                              TIPO DE DOCUMENTO
                            </label>
                            <select
                              className="form-control"
                              id="documentType"
                              value={documentType}
                              onChange={(e) => setDocumentType(e.target.value)}
                            >
                              <option value="VAZIO"></option>
                              {sortedDocumentTypes.map((docType) => (
                                <option key={docType.value} value={docType.value}>
                                  {docType.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <Button
                            color="success"
                            sx={{ my: 2 }}
                            size="medium"
                            variant="contained"
                            onClick={handleUpload}
                          >
                            Enviar Documento
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <Table
                              columns={collumnsDocumentos}
                              data={documents ? documents : []}
                              overflowUnset={true}
                              hiddenColluns={["arquivoId"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseComponent>
  );
};

export default ServidoresAdicionarPage;
