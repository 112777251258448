import React, { useState, useEffect } from 'react';
import {
  Grid, Card, CardContent, TextField, Menu, Button, Autocomplete, Tooltip, Chip, Box
} from '@mui/material';
import { FilterAltOutlined } from '@mui/icons-material';
import { getCidades, getEstados } from '../../../../services/endereco';
import { getEscolasAndPolos } from '../data/getEscolasAndPolos';

const BilingueInscricoesFilter = ({ filtros, setFiltros, handleSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState('');
  const [escolas, setEscolas] = useState([]);
  const [polos, setPolos] = useState([]);

  // Load filters from localStorage when component loads
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('bilingueFiltros'));
    if (savedFilters) {
      setFiltros(savedFilters);
    }
  }, [setFiltros]);

  // Save the filters in localStorage
  useEffect(() => {
    localStorage.setItem('bilingueFiltros', JSON.stringify(filtros));
  }, [filtros]);

  useEffect(() => {
    const fetchEstados = async () => {
      const response = await getEstados();
      setEstados(response.map((estado) => ({
        label: estado.descricao,
        value: estado.descricao,
        id: estado.id
      })));
    };
    fetchEstados();
  }, []);

  useEffect(() => {
    if (estadoSelecionado) {
      const fetchCidades = async () => {
        const response = await getCidades(estadoSelecionado.id);
        setCidades(response.map((cidade) => ({ label: cidade.nome })));
      };
      fetchCidades();
    }
  }, [estadoSelecionado]);

  useEffect(() => {
    const { escolas: newEscolas, polos: newPolos } = getEscolasAndPolos(filtros.tipoFormulario, filtros.redeEnsino);
    setEscolas(newEscolas);
    setPolos(newPolos);
  }, [filtros.tipoFormulario, filtros.redeEnsino]);

  const handleChange = (event, value, fieldName) => {
    const { name, value: fieldValue, checked, type } = event.target || {};
    setFiltros({
      ...filtros,
      [name || fieldName]: type === 'checkbox' ? checked : (value || fieldValue),
    });
  };

  const handleFilterSubmit = () => {
    handleSubmit();
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportar = () => {
    setFiltros({ ...filtros, exportar: true });
    handleSubmit();
    setFiltros((prev) => ({ ...prev, exportar: false }));
  };

  // Map of internal field names to user-friendly labels
  const filterLabels = {
    nomeCompleto: 'Nome Completo',
    dataNascimento: 'Data de Nascimento',
    cpf: 'CPF',
    uf: 'Estado (UF)',
    cidade: 'Cidade',
    nomeResponsavel: 'Nome do Responsável',
    rgResponsavel: 'RG do Responsável',
    cpfResponsavel: 'CPF do Responsável',
    telefoneResponsavel: 'Telefone do Responsável',
    emailResponsavel: 'E-mail do Responsável',
    tipoFormularioLabel: 'Tipo de Formulário',
    redeEnsino: 'Rede de Ensino',
    escola: 'Escola',
    polo: 'Polo',
    tipoDeficiencia: 'Tipo de Deficiência',
    atendimentoEspecializado: 'Atendimento Especializado',
    possuiAnexo: 'Possui Anexo',
    status: 'Status'
  };

  const filtrosAplicados = Object.keys(filtros).filter((key) => filtros[key] && key !== 'skip' && key !== 'limit' && key !== 'tipoFormulario' && key !== 'exportar');

  return (
    <>
      {/* Button Container */}
      <Box display="flex" gap={2} mb={2}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<FilterAltOutlined />}
          variant="contained"
          size="large"
          color="info"
        >
          Filtro
        </Button>

        {/* Exportar Button */}
        <Button variant="contained" color="secondary" onClick={handleExportar}>
          Exportar
        </Button>
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {/* Tipo de Formulário */}
              <Grid item xs={12}>
                <Autocomplete
                  options={[
                    { label: "Escola Bilíngue M. Sueli Amaral (Orientação Militar) – Araruama", value: "BILINGUE_MILITAR" },
                    { label: "Escola Bilíngue M. Prof. João Raposo (Orientação em Gastronomia e Hotelaria) – Araruama", value: "BILINGUE_GASTRONOMIA_HOTELARIA" },
                    { label: "Escola Bilíngue M. Oscarino Pereira de Andrade (Orientação em Agronegócio e Turismo Rural) – São Vicente de Paulo", value: "BILINGUE_AGRONEGOCIO_TURISMO_RURAL" }
                  ]}
                  getOptionLabel={(option) => option.label || ""}
                  value={filtros.tipoFormulario ? { label: filtros.tipoFormularioLabel || "", value: filtros.tipoFormulario } : null}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFiltros({
                        ...filtros,
                        tipoFormulario: newValue.value,
                        tipoFormularioLabel: newValue.label
                      });
                    } else {
                      setFiltros({
                        ...filtros,
                        tipoFormulario: '',
                        tipoFormularioLabel: ''
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Tipo de Formulário" fullWidth />}
                />
              </Grid>

              {/* Rede de Ensino */}
              <Grid item xs={12}>
                <Autocomplete
                  options={filtros.tipoFormulario === 'BILINGUE_AGRONEGOCIO_TURISMO_RURAL' ? ["MUNICIPAL"] : ["PRIVADA", "MUNICIPAL"]}
                  value={filtros.redeEnsino}
                  onChange={(event, value) => handleChange(event, value, 'redeEnsino')}
                  renderInput={(params) => <TextField {...params} label="Rede de Ensino" fullWidth />}
                />
              </Grid>

              {/* Escola */}
              <Grid item xs={12}>
                <Autocomplete
                  options={escolas}
                  value={filtros.escola}
                  onChange={(event, value) => handleChange(event, value, 'escola')}
                  renderInput={(params) => <TextField {...params} label="Escola" fullWidth />}
                />
              </Grid>

              {/* Polo */}
              <Grid item xs={12}>
                <Autocomplete
                  options={polos}
                  value={filtros.polo}
                  onChange={(event, value) => handleChange(event, value, 'polo')}
                  renderInput={(params) => <TextField {...params} label="Polo" fullWidth />}
                />
              </Grid>

              {/* Nome Completo */}
              <Grid item xs={6}>
                <TextField label="Nome Completo" name="nomeCompleto" value={filtros.nomeCompleto} onChange={handleChange} fullWidth />
              </Grid>

              {/* CPF */}
              <Grid item xs={6}>
                <TextField label="CPF" name="cpf" value={filtros.cpf} onChange={handleChange} fullWidth inputProps={{ maxLength: 14 }} />
              </Grid>

              {/* Estado */}
              <Grid item xs={6}>
                <Autocomplete
                  options={estados}
                  value={filtros.uf}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, 'uf');
                    setEstadoSelecionado(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Estado" fullWidth />}
                />
              </Grid>

              {/* Cidade */}
              <Grid item xs={6}>
                <Autocomplete
                  options={cidades}
                  value={filtros.cidade}
                  onChange={(event, newValue) => handleChange(event, newValue, 'cidade')}
                  renderInput={(params) => <TextField {...params} label="Cidade" fullWidth />}
                />
              </Grid>

              {/* Botão de Aplicar Filtros */}
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleFilterSubmit} fullWidth>
                  Aplicar Filtros
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Menu>

      {/* Renderizar filtros aplicados como tooltips */}
      <Box mt={4}>
        <Grid container spacing={1}>
          {filtrosAplicados.map((filtroKey, index) => (
            <Grid item key={index}>
              <Tooltip title={`${filterLabels[filtroKey]}: ${filtros[filtroKey]}`} arrow>
                <Chip label={`${filterLabels[filtroKey]}: ${filtros[filtroKey]}`} sx={{ backgroundColor: '#1976d2', color: '#fff' }} />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default BilingueInscricoesFilter;
