import React from 'react';
import { Box, Typography, TextField, IconButton, Grid } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/system';

const Root = styled(Box)(({ theme }) => ({
  height: '100vh',
  backgroundColor: '#4A007E', // Dark purple background
  padding: '0 20px',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Ajustado para organizar o conteúdo
  alignItems: 'center',
}));

const Logo = styled('img')({
  position: 'absolute',
  top: '10px',
  left: '20px',
  height: '50px',
});

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100%',
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '20px',
  width: '100%', // Agora ocupa 100% para ocupar as 12 colunas
}));

const IconButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const HeroImage = styled('img')({
  borderRadius: '50%',
  width: '300px', // Aumentado o tamanho da imagem
  height: '300px',
});

function EduAmigoFale() {
  return (
    <Root>
      <Logo src="/assets/images/eduAmigo/eduamigo-logo-white.webp" alt="EduAmigo Logo" />
      
      {/* Parte superior com 6 colunas para o texto e 6 colunas para a imagem */}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <ChatContainer>
            <Typography
              sx={{
                fontFamily: 'Comic Sans MS, sans-serif',
                fontWeight: 'bold',
                fontSize: '2rem',
                textAlign: 'center',
              }}
            >
              OLÁ, AMIGO VAMOS COMEÇAR A CONVERSAR?
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Comic Sans MS, sans-serif',
                fontSize: '1.2rem',
                textAlign: 'center',
              }}
            >
              PARA COMEÇAR DIGITE SUA DÚVIDA QUE EU VOU TE RESPONDER
            </Typography>
          </ChatContainer>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="center">
          <HeroImage src="/assets/images/eduAmigo/hero4.webp" alt="Hero" />
        </Grid>
      </Grid>

      {/* Chat abaixo, ocupando 12 colunas */}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <InputContainer>
            <TextFieldStyled
              placeholder="OIE, DIGITE SUA DÚVIDA AQUI"
              variant="outlined"
              InputProps={{
                style: { borderRadius: '20px', padding: '10px' },
              }}
            />
            <IconButtons>
              <IconButton color="primary" sx={{ backgroundColor: '#fff' }}>
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton color="primary" sx={{ backgroundColor: '#fff' }}>
                <MicIcon />
              </IconButton>
            </IconButtons>
          </InputContainer>
        </Grid>
      </Grid>
    </Root>
  );
}

export default EduAmigoFale;
