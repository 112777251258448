import api from ".";

export const criarFormularioBilingue = async (formulario) => {
    const response = await api.post("/formulario", formulario);
    return response.data;
    };

export const listarFormulariosBilingue = async () => {
    const response = await api.get("/formulario");
    return response.data;
    }

export const listarFormularioBilingue = async (id) => {
    const response = await api.get(`/formulario/${id}`);
    return response.data;
    }

export const listarEstatisticasBilingue = async () => {
    const response = await api.get("/formulario/estatisticas");
    return response.data;
    }

    export const listarInscricoesFiltroBilingue = async (filtro) => {
        let config = {};
      
        if (filtro.exportar === true) {
          config = {
            responseType: 'arraybuffer', // Para receber um arquivo binário
            headers: { 'Content-Type': 'application/json' } // Enviar como JSON
          };
        }
      
        const response = await api.post("/formulario/filtros", filtro, config);
      
        if (filtro.exportar === true && response?.data) {
          const fileName = 'relatorio-processo-bilingue.xlsx'; // Nome do arquivo a ser baixado
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Tipo de arquivo Excel
            encoding: 'UTF-8'
          });
      
          // Para navegadores que não suportam window.URL.createObjectURL (como IE)
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            // Criação do URL para o arquivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Atribuir o nome do arquivo
            document.body.appendChild(link);
            link.click(); // Disparar o download
            link.remove(); // Remover o link após o download
          }
        }
      
        return response.data;
      };
      

export const listarPorCpfBilingue = async (cpf) => {
    const response = await api.get(`/formulario/filtroCpf?cpf=${cpf}`);
    return response.data;
    }

    export const atualizarInscricaoBilingue = async (id, status, observacao) => {
        const response = await api.put(`/formulario/${id}`, { status, observacao });
        return response.data;
    }