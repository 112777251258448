import { useMemo } from 'react';
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/novaTabela";
import BaseAdministrativo from '../../baseAdministrativo';
import { useNavigate } from 'react-router-dom';
import urls from "../../../../utils/urls";
import { useQuery } from 'react-query';
import { deleteNutricaoGrupoProdutos, getNutricaoGrupoProdutos } from '../../../../services/nutricao';
import { toast } from 'react-toastify';
import urlsAdministrativo from '../../urlsAdministrativo';

const NutricaoCategoriaProdutos = () => {
  const navigate = useNavigate();

  const { data: groupData } = useQuery('getNutricaoGrupoProdutos', getNutricaoGrupoProdutos, {
    retry: false,
    enabled: true,
  })

  const { refetch: deleteGroup } = useQuery('deleteNutricaoGrupoProdutos', deleteNutricaoGrupoProdutos, {
    retry: false,
    enabled: false,
    onSuccess: (data) => {
      toast.success('Categoria de produtos Nutrição excluída com sucesso.')
    },
    onError: (data) => {
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  })

  const formatStatus = (status) => {
    if (status === true) {
      return 'Ativo'
    } else {
      return 'Inativo'
    }
  }

  const statusOptions = {
    true: <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "16px", color: "#FFFFFF", width: "100px", height: "25px", backgroundColor: "green" }}>
    <span >Ativo</span>
  </div>,
    false: <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "16px", color: "#FFFFFF", width: "100px", height: "25px", backgroundColor: "gray" }}>
    <span >Inativo</span>
  </div>,
  }

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Status', accessor: 'status', style: { display: "flex", justifyContent: "center", height: "61px" },
      Cell: ({ row }) => (
        statusOptions[row.original.status]
      )
    },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urls.administrativoNutricaoCategoriaProdutoCadastrar + row.original.id, {
              state: {
                id: row.original.id,
                descricao: row.original.descricao,
                status: row.original.status,
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deleteNutricaoGrupoProdutos(row.original.id)
                }
              }}
            >
              <i className="material-icons-two-tone">group_remove</i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, groupData]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Nutrição - Categoria de Produtos'} itens={['Administrativo', 'Nutrição', 'Categoria de produtos']} actions={{ link: urlsAdministrativo.nutricaoCategoriaProdutoCadastrar, text: "Nova Categoria" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} data={groupData ? groupData : []} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default NutricaoCategoriaProdutos;
