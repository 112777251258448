import { useMemo, useState } from 'react';
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/novaTabela";
import BaseAdministrativo from '../../baseAdministrativo';
import { useQuery, useMutation } from 'react-query';
import { getProcessos, deleteProcesso } from '../../../../services/administrativo/financeiro-processos'
import { useNavigate } from 'react-router-dom';
import urls from "../../../../utils/urls";
import { toast } from 'react-toastify';

const dataTable = [ //mock de processos
  {
    id: 1,
    nome: 'Aquisição',
    numero: '0123121456',
    dataVencimento: '19-05-2023',
    usuario: 'Fulano'
  },
  {
    id: 2,
    nome: 'Aquisição',
    numero: '0123121456',
    dataVencimento: '19-05-2023',
    usuario: 'Fulano'
  },
  {
    id: 3,
    nome: 'Aquisição',
    numero: '0123121456',
    dataVencimento: '19-05-2023',
    usuario: 'Fulano'
  },
  {
    id: 4,
    nome: 'Aquisição',
    numero: '0123121456',
    dataVencimento: '19-05-2023',
    usuario: 'Fulano'
  },
  {
    id: 5,
    nome: 'Aquisição',
    numero: '0123121456',
    dataVencimento: '19-05-2023',
    usuario: 'Fulano'
  },

]

const ProcessosTable = () => {
  const navigate = useNavigate();
  const [newData, setNewData] = useState([])

  const { data: processosData, refetch: refetchData } = useQuery('getProcessos', getProcessos, {
    retry: false,
    enabled: true,
    onSuccess: (data) => {
      // setNewData(data.data)
    },
  });

  const { mutate: deleteMutate } = useMutation(deleteProcesso, {
    onSuccess: () => {
      toast.success('Processo excluído com sucesso.');
      refetchData();
    },
    onError: () => {
      toast.error('Algo de errado aconteceu. Tente novamente mais tarde.')
    }
  })


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nomeProcesso' },
      { Header: 'Número', accessor: 'numeroProcesso' },
      {
        Header: 'Vencimento', accessor: 'dataVencimento',
        Cell: ({ row }) => (
          row.original.dataVencimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      { Header: 'Usuário', accessor: 'nomeUsuario' },
      {
        Header: 'Anexo', accessor: 'anexoId',
        Cell: ({ row }) => (
          <button
            className='btn btn-sm'
            style={{ padding: '8px' }}
            onClick={() => console.log('Anexo')}
            disabled={row.original.anexoId === '' || row.original.anexoId === null || !row.original.anexoId}
          >
            Baixar
          </button>
        )
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urls.administrativoFinanceiroProcessosCadastrar + row.original.id, {
              state: {
                id: row.original.id,
                nomeProcesso: row.original.nomeProcesso,
                numeroProcesso: row.original.numeroProcesso,
                dataVencimento: row.original.dataVencimento.split('T')[0],
              }
            })} className='btn btn-sm btn-primary'>
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>
            <button
              className='btn btn-sm btn-danger'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deleteMutate(row.original.id)
                }
              }}
            >
              <i className="material-icons-two-tone">group_remove</i> excluir
            </button>
          </>
        )
      },
    ],
    [navigate, newData]
  )

  return (
    <BaseAdministrativo>
      <Breadcrumb title={'Processos'} itens={['Administrativo', 'Financeiro', 'Processos']} actions={{ link: urls.administrativoFinanceiroProcessosCadastrar, text: "Novo Processo" }} />
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="card-body">
              <Table columns={columns} data={dataTable ? dataTable : []} />
            </div>
          </div>
        </div>
      </div>
    </BaseAdministrativo >
  )
};
export default ProcessosTable;
